import { Address } from 'shared/Redux/interfaces';

export default function getDefaultAddress(addresses: Address[]): Address {
  if (!addresses?.length) return undefined;

  const defaultAddresses = addresses.filter(a => a.address_type === 'default');

  if (defaultAddresses?.length) {
    const defaultAddress = defaultAddresses[0];

    if (defaultAddress?.address_detail) {
      let address_lines = [
        defaultAddress.address_detail.address_line_1,
        defaultAddress.address_detail.address_line_2,
        defaultAddress.address_detail.address_line_3
      ]
        .filter(d => d)
        .join('\n');

      const houseNumber = [
        defaultAddress.address_detail.house_number,
        defaultAddress.address_detail.house_number_extra
      ]
        .filter(d => d)
        .join('');

      const address = [defaultAddress.address_detail.street, houseNumber]
        .filter(d => d)
        .join(' ');

      const defaultAddressFormatted = [
        [address_lines, address, defaultAddress.address_detail.area_code]
          .filter(d => d)
          .join('\n'),
        defaultAddress.address_detail.city,
        '\n'
      ].join(' ');

      const defaultAddressShortFormatted = [
        defaultAddress.address_detail.address_line_1,
        defaultAddress.address_detail.address_line_2,
        defaultAddress.address_detail.address_line_3
      ]
        .filter(d => d)
        .join(', ');

      return {
        ...defaultAddress,
        address_detail: {
          ...defaultAddress.address_detail,
          display_short: defaultAddressFormatted,
          display_short_simple: defaultAddressShortFormatted
        }
      };
    }
  }

  return undefined;
}
