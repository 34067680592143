import Router from 'next/router';
import getConfig from 'next/config';

import gkLogo from 'public/gk-logo.png';
import gkFavicon from 'public/favicon.ico';

const env = getConfig()?.publicRuntimeConfig;

const favicon = env.appFavicon ? env.appFavicon : gkFavicon.src;
const logo = env.appLogo ? env.appLogo : gkLogo.src;
const logoPadding = Number(env.appLogoPadding)
  ? Number(env.appLogoPadding)
  : 0;
const logoBig = env.appLogoBig ? env.appLogoBig : logo;
const searchTimeout = 350;

const Globals = {
  settings: {
    version: env.version,
    commit: env.commit,
    appEnv: env.appEnv,
    logo,
    logoPadding,
    logoBig,
    favicon,
    searchTimeout,
    settingHolidayUse: env.settingHolidayUse,
    settingConsumerMemberType: env.settingConsumerMemberType,
    // Blueprint
    // { external:bool (If href is external), href:string, caption:string, icon:string }
    navigation_top: intl =>
      [
        env.sportsPortalHost
          ? {
              external: true,
              href: env.sportsPortalHost,
              caption: intl.formatMessage({
                id: 'sports_portal',
                defaultMessage: 'Sports portal'
              })
            }
          : null
      ].filter(c => c !== null),
    navigation_left: ({ intl, organization, defaultFilter }) => {
      const nav = [];

      nav.push({
        href: defaultFilter('/requests'),
        pathname: '/requests',
        icon: 'pen',
        caption: intl.formatMessage({
          id: 'requests',
          defaultMessage: 'Requests'
        })
      });
      nav.push({
        href: defaultFilter('/bookings'),
        pathname: '/bookings',
        icon: 'list',
        caption: intl.formatMessage({
          id: 'bookings',
          defaultMessage: 'Bookings'
        })
      });
      nav.push({
        href: defaultFilter('/closures'),
        pathname: '/closures',
        icon: 'report',
        caption: intl.formatMessage({
          id: 'closures',
          defaultMessage: 'Closures'
        })
      });
      nav.push({
        href: defaultFilter('/calendar'),
        pathname: '/calendar',
        icon: 'calendar',
        caption: intl.formatMessage({
          id: 'calendar',
          defaultMessage: 'Calendar'
        })
      });
      if (organization?.setting_notifications) {
        nav.push({
          href: '/notifications',
          pathname: '/notifications',
          icon: 'mail',
          caption: intl.formatMessage({
            id: 'messages',
            defaultMessage: 'Messages'
          })
        });
      }
      nav.push({
        href: defaultFilter('/domains'),
        pathname: '/locations',
        icon: 'location-marker',
        caption: intl.formatMessage({
          id: 'locations',
          defaultMessage: 'Locations'
        }),
        submenu: [
          {
            href: defaultFilter('/domains'),
            pathname: '/domains',
            caption: intl.formatMessage({
              id: 'domains',
              defaultMessage: 'Domains'
            })
          },
          {
            href: defaultFilter('/groups'),
            pathname: '/groups',
            caption: intl.formatMessage({
              id: 'groups',
              defaultMessage: 'Groups'
            })
          }
          // {
          //   href: defaultFilter('/units'),
          //   pathname: '/units',
          //   caption: intl.formatMessage({
          //     id: 'units',
          //     defaultMessage: 'Units'
          //   })
          // }
        ]
      });
      nav.push({
        href: '/consumers',
        pathname: '/consumers',
        icon: 'location-city',
        caption: intl.formatMessage({
          id: 'organizations',
          defaultMessage: 'Organizations'
        }),
        submenu: [
          {
            href: '/consumers',
            pathname: '/consumers',
            caption: intl.formatMessage({
              id: 'consumers',
              defaultMessage: 'Consumers'
            })
          },
          {
            href: '/providers',
            pathname: '/providers',
            caption: intl.formatMessage({
              id: 'providers',
              defaultMessage: 'Providers'
            })
          }
          // {
          //   href: '/organizations',
          //   pathname: '/organizations',
          //   caption: intl.formatMessage({
          //     id: 'organizations',
          //     defaultMessage: 'Organizations'
          //   })
          // }
        ]
      });

      nav.push({
        href: '/statistics',
        pathname: '/statistics',
        icon: 'chart',
        caption: intl.formatMessage({
          id: 'statistics',
          defaultMessage: 'Statistics'
        })
      });

      nav.push({
        href: '/trash',
        pathname: '/trash',
        icon: 'delete',
        caption: intl.formatMessage({
          id: 'trash',
          defaultMessage: 'Trash'
        })
      });
      // {
      //   href: '/settings',
      //   icon: 'setting',
      //   caption: intl.formatMessage({
      //     id: 'settings',
      //     defaultMessage: 'Settings'
      //   })
      // }

      return nav;
    },
    defaultPaginationSize: 25
  },
  settings_provider: {
    version: env.version,
    commit: env.commit,
    appEnv: env.appEnv,
    logo,
    logoPadding,
    logoBig,
    favicon,
    searchTimeout,
    navigation_top: intl =>
      [
        env.sportsPortalHost
          ? {
              external: true,
              href: env.sportsPortalHost,
              caption: intl.formatMessage({
                id: 'sports_portal',
                defaultMessage: 'Sports portal'
              })
            }
          : null
      ].filter(c => c !== null),
    navigation_left: ({ intl, organization, defaultFilter }) => {
      let nav = [];

      nav.push({
        href: defaultFilter('/requests'),
        icon: 'pen',
        caption: intl.formatMessage({
          id: 'requests',
          defaultMessage: 'Requests'
        })
      });
      nav.push({
        href: defaultFilter('/bookings'),
        pathname: '/bookings',
        icon: 'list',
        caption: intl.formatMessage({
          id: 'bookings',
          defaultMessage: 'Bookings'
        })
      });
      nav.push({
        href: defaultFilter('/closures'),
        pathname: '/closures',
        icon: 'report',
        caption: intl.formatMessage({
          id: 'closures',
          defaultMessage: 'Closures'
        })
      });
      nav.push({
        href: defaultFilter('/calendar'),
        pathname: '/calendar',
        icon: 'calendar',
        caption: intl.formatMessage({
          id: 'calendar',
          defaultMessage: 'Calendar'
        })
      });
      if (organization?.setting_notifications) {
        nav.push({
          href: '/notifications',
          pathname: '/notifications',
          icon: 'mail',
          caption: intl.formatMessage({
            id: 'messages',
            defaultMessage: 'Messages'
          })
        });
      }
      nav.push({
        href: defaultFilter('/domains'),
        pathname: '/locations',
        icon: 'location-marker',
        caption: intl.formatMessage({
          id: 'locations',
          defaultMessage: 'Locations'
        }),
        submenu: [
          {
            href: defaultFilter('/domains'),
            pathname: '/domains',
            caption: intl.formatMessage({
              id: 'domains',
              defaultMessage: 'Domains'
            })
          },
          {
            href: defaultFilter('/groups'),
            pathname: '/groups',
            caption: intl.formatMessage({
              id: 'groups',
              defaultMessage: 'Groups'
            })
          }
          // {
          //   href: defaultFilter('/units'),
          //   pathname: '/units',
          //   caption: intl.formatMessage({
          //     id: 'units',
          //     defaultMessage: 'Units'
          //   })
          // }
        ]
      });
      nav.push({
        href: '/consumers',
        pathname: '/consumers',
        icon: 'location-city',
        caption: intl.formatMessage({
          id: 'organizations',
          defaultMessage: 'Organizations'
        }),
        submenu: [
          {
            href: '/consumers',
            pathname: '/consumers',
            caption: intl.formatMessage({
              id: 'consumers',
              defaultMessage: 'Consumers'
            })
          },
          {
            href: '/providers',
            pathname: '/providers',
            caption: intl.formatMessage({
              id: 'providers',
              defaultMessage: 'Providers'
            })
          }
        ]
      });

      nav.push({
        href: '/statistics',
        pathname: '/statistics',
        icon: 'chart',
        caption: intl.formatMessage({
          id: 'statistics',
          defaultMessage: 'Statistics'
        })
      });

      nav.push({
        href: '/trash',
        pathname: '/trash',
        icon: 'delete',
        caption: intl.formatMessage({
          id: 'trash',
          defaultMessage: 'Trash'
        })
      });

      return nav;
    },

    defaultPaginationSize: 25
  },
  settings_consumer: {
    version: env.version,
    commit: env.commit,
    appEnv: env.appEnv,
    logo,
    logoPadding,
    logoBig,
    favicon,
    searchTimeout,
    navigation_top: intl =>
      [
        env.sportsPortalHost
          ? {
              external: true,
              href: env.sportsPortalHost,
              caption: intl.formatMessage({
                id: 'sports_portal',
                defaultMessage: 'Sports portal'
              })
            }
          : null
      ].filter(c => c !== null),
    navigation_left: ({ intl, defaultFilter }) => [
      {
        href: defaultFilter('/requests'),
        pathname: '/requests',
        icon: 'pen',
        caption: intl.formatMessage({
          id: 'requests',
          defaultMessage: 'Requests'
        })
      },
      {
        href: defaultFilter('/bookings'),
        pathname: '/bookings',
        icon: 'list',
        caption: intl.formatMessage({
          id: 'bookings',
          defaultMessage: 'Bookings'
        })
      },
      {
        href: defaultFilter('/calendar'),
        pathname: '/calendar',
        icon: 'calendar',
        caption: intl.formatMessage({
          id: 'calendar',
          defaultMessage: 'Calendar'
        })
      },
      {
        href: '/trash',
        pathname: '/trash',
        icon: 'delete',
        caption: intl.formatMessage({
          id: 'trash',
          defaultMessage: 'Trash'
        })
      }
    ],
    defaultPaginationSize: 25
  }
};

export function isServer() {
  return typeof window === 'undefined';
}

export function isClient() {
  return !isServer();
}

export function redirect(route, res) {
  if (isServer()) {
    res.writeHead(307, { Location: route }).end();
  } else {
    Router.replace(route);
  }
}

export default Globals;
