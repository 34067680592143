import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { Organization, Pagination } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { organizationsEntity } from '../schemas';
import { paginate, safeArray } from '../utils';

export const fetchOrganization = createAsyncThunk(
  'organizations/byId',
  async organizationId => {
    const response = await client.get(`/organizations/${organizationId}`);
    const normalized = normalize(response.data.data, organizationsEntity);

    return normalized.entities;
  }
);

export const fetchOrganizations = createAsyncThunk(
  'organizations/all',
  async (filter?: Pagination) => {
    const response = await client.get(`/organizations`, filter);
    const normalized = normalize(response.data.data, [organizationsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async organization => {
    const response = await client.post('/organizations', organization);
    const normalized = normalize(response.data.data, organizationsEntity);

    return normalized.entities;
  }
);

export const updateOrganization = createAsyncThunk(
  'organizations/update',
  async ({ id, organization }: { id: string; organization: Organization }) => {
    const response = await client.put(`/organizations/${id}`, {
      organization
    });
    const normalized = normalize(response.data.data, organizationsEntity);

    return normalized.entities;
  }
);

export const archiveOrganization = createAsyncThunk(
  'organizations/archive',
  async (id: number) => {
    await client.put(`/archive/organizations/${id}`);
    return id;
  }
);

export const unarchiveOrganization = createAsyncThunk(
  'organizations/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/organizations/${id}`);
    return id;
  }
);

export const deleteOrganization = createAsyncThunk(
  'organizations/delete',
  async (id: number) => {
    await client.delete(`/organizations/${id}`);
    return id;
  }
);

export const downloadOrganizationExport = createAsyncThunk(
  'organizations/export',
  async (_, thunkApi) => {
    try {
      return new Promise(resolve => {
        client
          .get(`/export`, {}, 'blob')
          .then(response => response.data)
          .then(async data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            const date = new Date().toISOString().split('T')[0];

            link.href = url;
            link.setAttribute(
              'download',
              `groundkeeper-rm-data-export-${date}.zip`
            );
            document.body.appendChild(link);
            link.click();

            resolve(null);
          });
      });
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

const organizationAdapter = createEntityAdapter<Organization>();
const initialState = organizationAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      organizationAdapter.upsertMany(
        state,
        safeArray(payload.organizations.entities)
      );
      state.pagination = payload.organizations.pagination;
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizations.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      organizationAdapter.upsertMany(state, safeArray(payload.organizations));
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizations.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganization.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrganization.fulfilled, (state, { payload }) => {
      organizationAdapter.upsertMany(state, safeArray(payload.organizations));
      state.isLoading = false;
    });
    builder.addCase(fetchOrganization.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createOrganization.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createOrganization.fulfilled, (state, { payload }) => {
      organizationAdapter.upsertMany(state, safeArray(payload.entities));
      state.isLoading = false;
    });
    builder.addCase(createOrganization.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateOrganization.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateOrganization.fulfilled, (state, { payload }) => {
      organizationAdapter.upsertMany(state, safeArray(payload.entities));
      state.isLoading = false;
    });
    builder.addCase(updateOrganization.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOrganization.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteOrganization.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOrganization.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const organizationSelectors =
  organizationAdapter.getSelectors<RootState>(state => state.organizations);

export default organizationSlice.reducer;
