import { AnyAction } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ApplicationState, ApplicationSettings } from '../interfaces';
import { login, logout } from './auth.slice';

const initialState: ApplicationState = {
  drawerOpen: false,
  isInitialized: false,
  session: null,
  history: [],
  settings: {
    timetable_show_repeat_element_drag_and_drop_warning: true
  }
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    initialized(state: ApplicationState) {
      state.isInitialized = true;
    },
    toggleDrawerOpen(state: ApplicationState) {
      state.drawerOpen = !state.drawerOpen;
    },
    historyPush(state, { payload }: PayloadAction<string>) {
      const historyLength = 100;
      state.history = [...state.history, payload].slice(-historyLength);
    },
    editSettings(state, { payload }: PayloadAction<Partial<ApplicationState>>) {
      state.settings = { ...state.settings, ...payload };
    }
  },
  extraReducers: builder => {
    builder.addCase(
      HYDRATE,
      (state: ApplicationState, { payload }: AnyAction) => {
        state.isInitialized = payload.application.isInitialized;
      }
    );
    builder.addCase(login.fulfilled, (state: ApplicationState, action) => {
      state.session = action.payload.access_token;
    });
    builder.addCase(logout, (state: ApplicationState) => {
      state.session = null;
      state.isInitialized = false;
    });
  }
});

export const { initialized, toggleDrawerOpen, historyPush, editSettings } =
  applicationSlice.actions;

export default applicationSlice.reducer;
