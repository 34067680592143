import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { Pagination, ProviderMember } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { fetchOrganizations } from './organization.slice';
import {
  createProvider,
  fetchProvider,
  fetchProviders,
  updateProvider
} from './provider.slice';
import { providerMembersEntity } from '../schemas';
import { paginate, safeArray } from '../utils';

export const fetchProviderMember = createAsyncThunk(
  'providerMembers/byId',
  async providerMemberId => {
    const response = await client.get(`/provider_members/${providerMemberId}`);
    const normalized = normalize(response.data.data, providerMembersEntity);

    return normalized.entities;
  }
);

export const fetchProviderMembers = createAsyncThunk(
  'providerMembers/all',
  async (filter: Pagination) => {
    const response = await client.get('/provider_members', filter);
    const normalized = normalize(response.data.data, [providerMembersEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createProviderMember = createAsyncThunk(
  'providerMembers/create',
  async member => {
    const response = await client.post('/provider_members', {
      provider_member: member
    });
    const normalized = normalize(response.data.data, providerMembersEntity);

    return normalized.entities;
  }
);

export const updateProviderMember = createAsyncThunk(
  'providerMembers/update',
  async ({
    id,
    providerMember
  }: {
    id: string;
    providerMember: ProviderMember;
  }) => {
    const response = await client.put(`/provider_members/${id}`, {
      provider_member: providerMember
    });
    const normalized = normalize(response.data.data, providerMembersEntity);

    return normalized.entities;
  }
);

export const archiveProviderMember = createAsyncThunk(
  'providerMembers/archive',
  async (id: number) => {
    await client.put(`/archive/provider_members/${id}`);
    return id;
  }
);

export const unarchiveProviderMember = createAsyncThunk(
  'providerMembers/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/provider_members/${id}`);
    return id;
  }
);

export const deleteProviderMember = createAsyncThunk(
  'provider_members/delete',
  async (id: number) => {
    await client.delete(`/provider_members/${id}`);
    return id;
  }
);

const providerMemberAdapter = createEntityAdapter<ProviderMember>();
const initialState = providerMemberAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const providerMemberSlice = createSlice({
  name: 'providerMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers.entities)
      );
      state.pagination = payload.providerMembers.pagination;
    });
    builder.addCase(fetchProviderMembers.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchProviderMembers.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(fetchProviderMembers.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchProviderMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchProviderMember.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(fetchProviderMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createProviderMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProviderMember.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(createProviderMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProviderMember.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(updateProviderMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteProviderMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProviderMember.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteProviderMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchProvider.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
    });
    builder.addCase(createProvider.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
    });
    builder.addCase(updateProvider.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      providerMemberAdapter.upsertMany(
        state,
        safeArray(payload.providerMembers)
      );
    });
  }
});

export const providerMemberSelectors =
  providerMemberAdapter.getSelectors<RootState>(state => state.providerMembers);

export default providerMemberSlice.reducer;
