import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Tabbar from './Tabbar';

const useStyles = makeStyles(theme => ({
  tabbarPanel: {
    padding: 0,
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    left: 0,
    background: theme.color.background.default,
    borderTop: `1px solid ${theme.color.border.main}`
  }
}));

export default function TabbarTabPanel(props) {
  const classes = useStyles(props);
  const tabbarContext = useContext(Tabbar.Context);

  return (
    tabbarContext.index === props.index && (
      <Box p={1} className={classes.tabbarPanel}>
        {props.children}
      </Box>
    )
  );
}

TabbarTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

TabbarTabPanel.defaultProps = {
  children: undefined
};
