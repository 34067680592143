import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';

import Hoverbox from '../Hoverbox';
import addDays from '../../utils/date/addDays';
import convertToString from '../../utils/date/convertToString';
import isSameDayDate from '../../utils/date/isSameDayDate';
import setTime from '../../utils/date/setTime';
import {
  addMinutes,
  isBetween,
  isBefore,
  formatTime,
  isSameTime
} from '../../utils';

const HOURS = 24;
const WEEKDAYS = 7;
const MINUTES = 60;

function timeStringToHourDecimal(string) {
  const maybeFormattedTime = formatTime(string);

  if (maybeFormattedTime) {
    return maybeFormattedTime.hour + maybeFormattedTime.minutes / 60;
  }

  return 0;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflow: 'auto'
  },
  labels: {
    marginLeft: ({ state, props }) =>
      state.config.offset +
      (props?.customize?.style?.timesOffset ?? state.config.timesOffset),
    transition: '.1s filter linear'
  },
  domains: {
    display: 'flex',
    color: theme.color.text.main,
    flexShrink: 0,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '0.25rem'
  },
  domain: {
    color: theme.color.text.main,
    flexShrink: 0,
    fontWeight: 600,
    textAlign: 'center',
    hyphens: 'auto'
  },
  groups: {
    display: 'flex',
    color: theme.color.text.main,
    flexShrink: 0,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '0.25rem'
  },
  group: {
    color: theme.color.text.main,
    flexShrink: 0,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    hyphens: 'auto'
  },
  units: {
    display: 'flex',
    marginBottom: '0.5rem'
  },
  unit: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0
  },
  unitSymbol: {
    border: `1px solid ${theme.color.common.grey.main}`,
    borderRadius: '50%',
    color: theme.color.common.grey.main,
    cursor: 'help',
    flexShrink: 0,
    fontSize: '0.8rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    width: '1.2rem',
    '&:hover': {
      background: theme.color.primary.main,
      color: theme.color.primary.contrastText,
      borderColor: theme.color.primary.main
    }
  },
  weekendDay: {
    color: theme.color.text.light
  },
  timetable: {
    opacity: ({ state }) => (state.loading ? 0.5 : 1),
    pointerEvents: ({ state }) => (state.loading ? 'none' : 'auto'),
    height: ({ state, props }) =>
      timeStringToHourDecimal(props.showTill) * state.config.slotHeight +
      state.config.offset -
      timeStringToHourDecimal(props.showFrom) * state.config.slotHeight +
      state.config.offset,
    width: ({ state, props }) =>
      state.config.slotWidth * state.config.units.length +
      state.config.offset +
      (props?.customize?.style?.timesOffset ?? state.config.timesOffset),
    minWidth: '100%',
    overflow: 'hidden'
  },
  timeAndEvents: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    marginTop: ({ props, state }) =>
      -(timeStringToHourDecimal(props.showFrom) * state.config.slotHeight)
  },
  times: {
    flexShrink: 0,
    width: ({ state, props }) =>
      props?.customize?.style?.timesOffset ?? state.config.timesOffset
  },
  time: {
    color: theme.color.text.main,
    height: ({ state }) => state.config.slotHeight
  },
  elements: {
    overflow: 'hidden',
    position: 'relative'
  },
  element: {
    alignItems: 'center',
    background: theme.color.primary.main,
    border: `.5px solid ${theme.color.background.default}`,
    borderRadius: '0.5rem',
    boxSizing: 'border-box',
    color: theme.color.text.main,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    padding: '1rem .1rem',
    position: 'absolute',
    textAlign: 'center',
    userSelect: 'none'
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  elementsAllDay: {
    width: ({ state }) => state.config.slotWidth * state.config.units.length,
    boxSizing: 'border-box'
  },
  elementAllDay: {
    background: theme.color.primary.main,
    color: theme.color.text.main,
    fontSize: '0.75rem',
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 .25rem',
    width: '100%',
    boxSizing: 'border-box'
  },
  dateEndResize: {
    position: 'absolute',
    bottom: 0,
    boxSizing: 'border-box',
    padding: '.25rem',
    height: '1px',
    width: '100%',
    opacity: 0.2,
    '&:hover': {
      cursor: 'row-resize'
    }
  },
  unitsResize: {
    position: 'absolute',
    top: 0,
    right: 0,
    boxSizing: 'border-box',
    padding: '.25rem',
    height: '100%',
    width: '1px',
    opacity: 0.2,
    '&:hover': {
      cursor: 'col-resize'
    }
  },
  loadingGrid: {
    background: theme.color.border.light,
    height: '1px'
  }
}));

function sortDomainUnits(domains) {
  if (!domains) return [];

  // Copy domains to make sure we do not mutate redux array
  // And sort them by name
  const updatedDomains = [...domains].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );

  for (let d = 0; d < updatedDomains.length; d += 1) {
    if (updatedDomains[d].groups.length) {
      // Sort groups by name ascending
      updatedDomains[d].groups = [...updatedDomains[d].groups].sort((a, b) =>
        a?.name?.localeCompare(b?.name)
      );

      for (let g = 0; g < updatedDomains[d].groups.length; g += 1) {
        updatedDomains[d].groups[g].units = updatedDomains[d].groups[g].units
          ? [...updatedDomains[d]?.groups[g]?.units].sort(
              (a, b) => a.order - b.order
            )
          : [];
      }
    }
  }

  return updatedDomains;
}

function units(domains) {
  if (!domains) return [];

  if (!Array.isArray(domains)) domains = [domains];

  domains = sortDomainUnits(domains);
  let units = [];

  for (let d = 0; d < domains.length; d += 1) {
    for (let g = 0; g < domains[d].groups?.length; g += 1) {
      if (domains[d].groups[g].units?.length) {
        units.push(...domains[d].groups[g].units);
      }
    }
  }

  return units;
}

function groups(domains) {
  if (!domains) return [];

  if (!Array.isArray(domains)) domains = [domains];

  domains = sortDomainUnits(domains);
  let groups = [];

  for (let d = 0; d < domains.length; d += 1) {
    if (domains[d].groups?.length) {
      groups.push(...domains[d].groups);
    }
  }

  return groups;
}

export default function TimeTableDay(props) {
  const DEFAULT_CONFIG = {
    timesOffset: 48, // Fixed width of timeline. Should be moved to dynamic calculation.
    offset: props.customize?.style?.offset ?? 10, // The offset to make clear events hang over
    slotWidth: props.slotWidth, // Width a single unit
    slotHeight: 64, // Height of 1 hour
    slotPadding: 8, // Space set free to enable click on unit with running event
    timeStep: 15, // Steps in timetable - 5mins
    lineWidth: 0.5 // Width of grid lines
  };

  const [config, setConfig] = useState({
    ...DEFAULT_CONFIG,
    domains: sortDomainUnits(props.domains),
    groups: groups(props.domains),
    units: units(props.domains),
    id: `timetable-${Math.floor(Math.random() * 100)}`
  });
  const configRef = useRef(config);
  configRef.current = config;
  const propsRef = useRef(props);
  propsRef.current = props;

  const [loading, setLoading] = useState(props.loading);
  const [data, setData] = useState(props.data);
  const [clickDummy, setClickDummy] = useState();
  const [initialized, setInitialized] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ props, state: { config, loading } });

  const canvasRef = useRef(null);
  const wrapperRef = useRef(null);
  const elementsRef = useRef();
  const clickDummyRef = useRef();
  let resizeTimeout = null;

  /*
  Make sure we update config
  */
  useEffect(() => {
    setConfig(c => ({ ...c, offset: props.customize?.style?.offset ?? 10 }));
  }, [props.customize?.style?.offset]);

  /*
  Make sure local events are always up to date
  */
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  /*
  Allow simple updating events on drag and drop/resize change
  */
  function updateData(element, entity) {
    const updatedData = { ...data };

    for (let i = 0; i < data[entity].length; i += 1) {
      if (data[entity][i].id === element.id) {
        updatedData[entity][i] = element;
      }
    }

    setData(updatedData);
  }
  /*
  Calculate slot width based on units and screen size
  */
  function calculateSlotWidth(c) {
    const u = units(propsRef?.current.domains);
    const slotWidth =
      (wrapperRef?.current?.clientWidth -
        (props?.customize?.style?.timesOffset ?? c.timesOffset) -
        c.offset) /
      (u.length || 1);

    // Make sure slot size is big enough to display
    // unit labels
    return slotWidth < DEFAULT_CONFIG.slotWidth
      ? DEFAULT_CONFIG.slotWidth
      : slotWidth;
  }

  /*
  This effect runs on initial client side render and calculates
  the slot width based on user screen size. It recalculates
  the slot width on window resize.
  */
  useEffect(() => {
    setTimeout(() => {
      setConfig({
        ...config,
        slotWidth: calculateSlotWidth(config)
      });
      setInitialized(true);
    }, 100);

    function handleResize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(async () => {
        setConfig({
          ...configRef.current,
          slotWidth: calculateSlotWidth(configRef.current)
        });
      }, 100);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  /*
  Make sure we recalculate on domains change
  */
  useEffect(() => {
    if (JSON.stringify(props.domains) !== JSON.stringify(config.domains)) {
      setConfig({
        ...config,
        domains: sortDomainUnits(props.domains),
        groups: groups(props.domains),
        units: units(props.domains),
        slotWidth: calculateSlotWidth(config)
      });
    }
  }, [props.domains]);

  /*
    The component config contains all necessary information
    for rendering. This effect paints a canvas on config change.
    The canvas acts as background for the timetable.
    */
  useEffect(() => {
    if (canvasRef?.current && wrapperRef?.current) {
      const canvas = canvasRef.current;
      canvas.id = config.id;
      canvas.width = config.slotWidth * config.units.length + config.offset;
      canvas.height = HOURS * config.slotHeight + config.offset;
      const context = canvas.getContext('2d');
      const lineWidth = props.customize?.style?.lineWidth ?? config.lineWidth;

      // domains
      let u = 0;
      for (let i = 0; i < config.domains.length; i += 1) {
        context.beginPath();
        context.moveTo(config.slotWidth * u + config.offset, 0);
        context.lineTo(config.slotWidth * u + config.offset, canvas.height);
        context.lineWidth = lineWidth;
        context.strokeStyle = theme.color.common.grey.main;
        context.stroke();

        u = u + units(config.domains[i])?.length;
      }

      u = config.units.length ? config.units[0] : null;
      for (let i = 0; i < config.units.length; i += 1) {
        if (config.units[i].group_id !== u?.group_id) {
          context.beginPath();
          context.moveTo(config.slotWidth * i + config.offset, 0);
          context.lineTo(config.slotWidth * i + config.offset, canvas.height);
          context.lineWidth = lineWidth / 2;
          context.strokeStyle = theme.color.common.grey.main;
          context.stroke();

          u = config.units[i];
        }
      }

      // Units
      for (let i = 0; i < config.units.length; i += 1) {
        context.beginPath();
        context.moveTo(config.slotWidth * i + config.offset, 0);
        context.lineTo(config.slotWidth * i + config.offset, canvas.height);
        context.lineWidth = lineWidth / 4;
        context.strokeStyle = theme.color.common.grey.main;
        context.stroke();
      }

      // Days
      [...Array(WEEKDAYS + 1).keys()].forEach(i => {
        context.beginPath();
        context.moveTo(
          config.slotWidth * config.units.length * i +
            config.offset +
            lineWidth,
          0
        );
        context.lineTo(
          config.slotWidth * config.units.length * i +
            config.offset +
            lineWidth,
          canvas.height
        );
        context.lineWidth = lineWidth;
        context.strokeStyle = theme.color.common.grey.main;
        context.stroke();
      });

      // Hours
      [...Array(HOURS).keys()].forEach(i => {
        context.beginPath();
        context.moveTo(0, config.slotHeight * i + config.offset + lineWidth);
        context.lineTo(
          canvas.width,
          config.slotHeight * i + config.offset + lineWidth
        );
        context.lineWidth = lineWidth;
        context.strokeStyle = theme.color.common.grey.main;
        context.stroke();
      });

      // Make sure canvas is painted
      // Otherwise we get flashes with wrong timetable dimensions
      setTimeout(() => {
        setLoading(props.loading || false);
      });
    }
  }, [config]);

  function getUnitIndex(unit) {
    for (let i = 0; i < config.units.length; i += 1) {
      if (unit?.id === config.units[i].id) {
        return i;
      }
    }

    // This is just a hack to make sure events
    // that are not in date are hidden. This usually
    // does not happen in production because the
    // backend excludes data.
    return -100;
  }

  function getElementDates(element) {
    let current = new Date(element.start);
    const days = [current];

    // Check if Element is current day
    if (!isSameDayDate(current, props.day)) return [];

    while (!isSameDayDate(current, new Date(element.end))) {
      current = addDays(current, 1);
      days.push(current);
    }

    return days.map((d, i) => ({
      index: i,
      group_id: element.group_id,
      start: i === 0 ? new Date(element.start) : setTime(new Date(d), 0, 0),
      allDay: i !== 0 && i !== days.length - 1,
      end:
        i === days.length - 1
          ? new Date(element.end)
          : setTime(new Date(d), 23, 59)
    }));
  }

  // Get the coordiantes of and event/date. There is a mechanism to make sure
  // we only place events in config given time steps and do not cross days.
  function insertElement(element, date, position) {
    const yPercentage = position.y / elementsRef.current.offsetHeight;
    const yMinutes =
      Math.floor((yPercentage * HOURS * MINUTES) / config.timeStep) *
      config.timeStep;
    const yPixels = (yMinutes / MINUTES) * config.slotHeight + config.offset;

    const xPercentage = position.x / elementsRef.current.offsetWidth;
    let xUnit =
      Math.floor(xPercentage * config.units.length) % config.units.length;

    // Disable event drag and drop into other groups and unbookable groups
    const group = config.groups.filter(
      g => g.id === element.group_id && !g?.unbookable
    )[0];

    // Check if is outside group
    // Make we check for first and last unit of event
    if (
      config.units[xUnit]?.group_id !== group.id ||
      config.units[
        Math.min(config.units.length, xUnit + element.units.length - 1)
      ]?.group_id !== group?.id
    ) {
      const groupUnits = config.units.filter(
        u => u.group_id === element.group_id
      );
      const firstGroupUnitIndex = config.units.findIndex(
        u => u.id === groupUnits[0].id
      );
      const lastGroupUnitIndex = config.units.findIndex(
        u => u.id === groupUnits[groupUnits.length - 1].id
      );

      if (xUnit < firstGroupUnitIndex) {
        xUnit = firstGroupUnitIndex;
      }

      if (xUnit + element.units.length > lastGroupUnitIndex) {
        xUnit = lastGroupUnitIndex - element.units.length + 1;
      }
    }

    const xPixels =
      (xUnit / config.units.length) * config.slotWidth * config.units.length +
      config.offset;

    // Make sure outer boundaries applied
    // Left boundary
    let x = xPixels < config.offset ? config.offset : xPixels;
    // Right boundary
    x =
      x >
      elementsRef.current.offsetWidth - element.units.length * config.slotWidth
        ? elementsRef.current.offsetWidth -
          element.units.length * config.slotWidth
        : x;
    // Top boundary
    let y = yPixels < config.offset ? config.offset : yPixels;
    // Bottom boundary
    const height =
      ((new Date(date.end) - new Date(date.start)) / 1000 / MINUTES / MINUTES) *
      config.slotHeight;
    y =
      y > elementsRef.current.offsetHeight - height
        ? elementsRef.current.offsetHeight - height
        : y;

    return {
      x: x,
      y: y
    };
  }

  /*
    Give me some pixels and I tell you which unit is there.
    Optionally give me an event and you get all units for this
    event starting from the selected unit.
    */
  function pixelsToUnits(x, event = undefined) {
    const startIndex = Math.floor(
      ((x - config.offset) / config.slotWidth) % config.units.length
    );

    const endIndex = startIndex + event?.units?.length;

    return event
      ? config.units.slice(startIndex, endIndex)
      : [config.units[startIndex]];
  }

  /*
  Click somewhere on the timetable and you receive the date of
  your destination.
  */
  function pixelsToDate(x, y) {
    const decimalTime = (y - config.offset) / config.slotHeight;
    const hour = Math.ceil(decimalTime);
    const minute =
      Math.ceil(Math.ceil((decimalTime - hour) * MINUTES) / config.timeStep) *
      config.timeStep;

    return setTime(props.day, hour, minute);
  }

  /*
  Handles drag and drop & event click. It recognizes dragging and
  does the correct thing.
  */
  function elementHandler(e, element, date, entity) {
    e.stopPropagation();
    let dragged;
    let position;

    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}']`
    );

    const origins = [];
    let target;
    for (let i = 0; i < targets?.length; i += 1) {
      if (
        Number(targets[i].attributes.getNamedItem('data-date').value) ===
        date.index
      ) {
        target = targets[i];
      }

      origins.push({
        x: targets[i].offsetLeft,
        y: targets[i].offsetTop
      });
    }

    // Make sure that click on label does work by
    // using absolute click position based on date.index
    const rect = elementsRef.current.getBoundingClientRect();
    const click = {
      left: target?.offsetLeft,
      top: target?.offsetTop,
      x: e.clientX - rect.left - target.offsetLeft,
      y: e.clientY - rect.top - target.offsetTop
    };

    if (
      props.permissions?.dragAndDrop(element, entity) &&
      props.onDragAndDrop &&
      !element.locked
    ) {
      elementsRef.current.style.cursor = 'move';
    }

    const handleMouseMove = e => {
      position = insertElement(element, date, {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top - click.y
      });
      dragged =
        Math.ceil(position.x) !== click.left ||
        Math.ceil(position.y) !== click.top;

      const relativeX = position.x - click.left;
      const relativeY = position.y - click.top;
      for (let i = 0; i < targets?.length; i += 1) {
        targets[i].style.left = `${origins[i].x + relativeX}px`;
        targets[i].style.top = `${origins[i].y + relativeY}px`;
      }
    };

    const handleMouseUp = e => {
      e.stopPropagation();

      if (props.onDragAndDrop && !element.locked)
        elementsRef.current.removeEventListener('mousemove', handleMouseMove);

      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      if (
        props.permissions?.dragAndDrop(element, entity) &&
        dragged &&
        !element.locked
      ) {
        // Get start and end date based on pixels
        let start = pixelsToDate(position.x, position.y);
        const timerange = new Date(element.end) - new Date(element.start);
        let end = new Date(start.getTime() + timerange);

        // Check if dragged outside day
        if (!isSameDayDate(start, end)) {
          const maxEnd = setTime(start, 23, 55);
          const rangeOutOfBounce = end - maxEnd;
          start = start - rangeOutOfBounce;
          end = end - rangeOutOfBounce;
        }

        // Get units based on pixels
        const units = pixelsToUnits(position.x, element);

        // Update local state
        const updatedElement = {
          ...element,
          start,
          end,
          units
        };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onDragAndDrop({
            before: element,
            after: updatedElement
          });
        });
      } else {
        if (
          props.permissions?.elementClick(element, entity) &&
          props.onElementClick &&
          target.contains(e.target)
        ) {
          const units = pixelsToUnits(click.x, element);

          // Make sure listeners are removed
          setTimeout(() => {
            props.onElementClick(target, { units, element, entity });
          });
        }
      }

      elementsRef.current.style.cursor = 'default';
    };

    if (
      props.permissions?.dragAndDrop(element, entity) &&
      props.onDragAndDrop &&
      !element.locked
    )
      elementsRef.current.addEventListener('mousemove', handleMouseMove);

    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  /*
  Pass through the clicks inside the timetable to props. It
  only handles clicks for empty spaces. If you click on events the
  `eventHandler` does stuff.
  */
  function handleSlotClick(e) {
    // Make sure only clicks are recognized that are outside of an event
    // by comparing target.id and canvas.id
    if (
      props.permissions?.slotClick(e) &&
      props.onSlotClick &&
      e.target.id === config.id
    ) {
      const units = pixelsToUnits(e.nativeEvent.offsetX);
      const date = pixelsToDate(e.nativeEvent.offsetX, e.nativeEvent.offsetY);

      // Use a click dummy to make sure there is an element we can use
      // on scroll behaviour
      setClickDummy(
        <div
          ref={clickDummyRef}
          style={{
            position: 'absolute',
            left: e.nativeEvent.offsetX,
            top: e.nativeEvent.offsetY,
            height: 0,
            width: 0
          }}
        />
      );

      setTimeout(() => {
        props.onSlotClick(
          // Create a virtual dom element for popperjs processing
          // https://popper.js.org/docs/v2/virtual-elements/
          clickDummyRef?.current,
          // There is only a single unit possible on mouse click
          { unit: units[0], date }
        );
      }, 100);
    }

    return true;
  }

  /*
  Enables resizing the height/end date of an event/date.
  */
  function resizeDateEnd(e, element, date, entity) {
    e.stopPropagation();

    const rect = elementsRef.current.getBoundingClientRect();
    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}'][data-date='${date.index}']`
    );
    let end;

    // Add :active selector for styling purposes
    targets[0].focus();

    const handleMouseMove = e => {
      end = pixelsToDate(e.clientX - rect.left, e.clientY - rect.top);

      // Make sure we stay on current day.
      // `pixelsToDate` outputs different day if we move cursor.
      const resizedTime = setTime(date.end, end.getHours(), end.getMinutes());
      const minimumStart = addMinutes(new Date(date.start), config.timeStep);
      end = isBefore(resizedTime, minimumStart) ? minimumStart : resizedTime;

      targets[0].style.height = `${
        date.allDay
          ? 48 * config.slotHeight
          : ((new Date(end) - new Date(date.start)) /
              1000 /
              MINUTES /
              MINUTES) *
            config.slotHeight
      }px`;
    };

    const handleMouseUp = e => {
      elementsRef.current.removeEventListener('mousemove', handleMouseMove);
      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      // Only update if time changed
      if (element?.end && end && !isSameTime(new Date(element?.end), end)) {
        // Update local state
        const updatedElement = { ...element, end };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onResizeDateEnd({
            before: element,
            after: updatedElement
          });
          targets[0].blur();
        });
      }
    };

    elementsRef.current.addEventListener('mousemove', handleMouseMove);
    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  /*
  Enables resizing the units an event/date.
  */
  function resizeUnits(e, element, date, entity) {
    e.stopPropagation();
    const rect = elementsRef.current.getBoundingClientRect();
    const targets = elementsRef?.current?.querySelectorAll(
      `[data-event='${element.id}']`
    );

    let units = [...element.units];

    const handleMouseMove = e => {
      let mouseUnit = pixelsToUnits(e.clientX - rect.left)[0];

      // Make sure we only allow resize in same group
      if (mouseUnit?.group_id === date.group_id) {
        let included = false;

        for (let i = 0; i < units.length && !included; i++) {
          if (units[i].id === mouseUnit.id) {
            // Remove unit if we mouse over previous unit
            if (i + 1 < units.length) {
              units.splice(i + 1, 1);
            }

            included = true;
          }
        }

        // Make sure we only add units that a right aligned of units
        if (!included && mouseUnit.order > units[units.length - 1].order) {
          units.push(mouseUnit);
        }

        units = units.sort((a, b) => a.order - b.order);

        targets[0].style.width = `${
          units.length * config.slotWidth - config.slotPadding
        }px`;
      }
    };

    const handleMouseUp = e => {
      elementsRef.current.removeEventListener('mousemove', handleMouseMove);
      elementsRef.current.removeEventListener('mouseup', handleMouseUp);

      // Make sure we only update if there is a unit change
      if (
        JSON.stringify(units.map(u => u.id)) !==
        JSON.stringify(element.units.map(u => u.id))
      ) {
        // Update local state
        const updatedElement = { ...element, units };
        updateData(updatedElement, entity);

        // Make sure listeners are removed
        setTimeout(() => {
          props.onResizeUnits({
            before: element,
            after: updatedElement
          });
          targets[0].blur();
        });
      }
    };

    elementsRef.current.addEventListener('mousemove', handleMouseMove);
    elementsRef.current.addEventListener('mouseup', handleMouseUp);
  }

  return (
    <div id="timetable-wrapper" ref={wrapperRef} className={classes.wrapper}>
      {!initialized || loading ? (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap={5}
          data-testid="loading-indicator"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={3.3}
            ml={2}
          >
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={128} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={164} />
          </Box>
          {!initialized
            ? [...Array(12).keys()].map(i => (
                <Box key={i} display="flex" alignItems="center">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem' }}
                    width={48}
                    mb={2}
                  />
                  <Box className={classes.loadingGrid} width="100%" ml={2} />
                </Box>
              ))
            : null}
        </Box>
      ) : null}
      <div
        style={{
          display: !initialized || loading ? 'none' : 'block'
        }}
        className={classes.labels}
      >
        {props.customize.domainLabel ? (
          <div className={classes.domains}>
            {config.domains.map(domain => (
              <div
                key={domain.id}
                className={classes.domain}
                style={{
                  width: config.slotWidth * units(domain)?.length
                }}
              >
                {props.customize.domainLabel(domain)}
              </div>
            ))}
          </div>
        ) : null}
        {props.customize.groupLabel ? (
          <div className={classes.groups}>
            {config.domains.map(domain =>
              domain.groups.map(group => (
                <div
                  key={group.id}
                  className={classes.group}
                  style={{
                    width: config.slotWidth * group.units?.length
                  }}
                >
                  {props.customize.groupLabel(group)}
                </div>
              ))
            )}
          </div>
        ) : null}
        <div className={classes.units}>
          {config.domains.map(domain =>
            domain.groups.map(group =>
              group.units.map((unit, i) => (
                <div
                  key={`${i}-unit-label-${unit.id}`}
                  className={classes.unit}
                  style={{
                    width: config.slotWidth,
                    visibility: group.units.length > 1 ? 'visible' : 'hidden'
                  }}
                >
                  <Hoverbox
                    position={props.mode === 'default' ? 'left' : 'bottom'}
                    id={`${i}-unit-label-${unit.id}`}
                    mode="click"
                    target={<div className={classes.unitSymbol}>{i + 1}</div>}
                  >
                    <div className={classes.unitName}>{unit.name}</div>
                  </Hoverbox>
                </div>
              ))
            )
          )}
        </div>
        <div className={classes.elementsAllDay}>
          {Object.keys(data).map(entity =>
            data[entity].map(element =>
              // Check if Element is all-day and current day
              isSameDayDate(new Date(element.date), props.day) ||
              (!element.units?.length &&
                isBetween(
                  setTime(new Date(element.start), 0, 0, 0),
                  props.day,
                  setTime(new Date(element.end), 0, 0, 0)
                )) ? (
                <div
                  key={element.name}
                  title={element.name}
                  data-element={element.id}
                  className={`${
                    classes.elementAllDay
                  } ${props.customize?.element?.className(element, entity)}`}
                  data-testclass="time-table-all-day"
                >
                  {element.name}
                </div>
              ) : null
            )
          )}
        </div>
      </div>
      <div
        data-testid="timetable"
        className={classes.timetable}
        style={{
          visibility: !initialized ? 'hidden' : 'visible'
        }}
      >
        <div className={classes.timeAndEvents}>
          <div className={classes.times}>
            {[...Array(HOURS).keys()].map(h =>
              loading ? (
                <Box key={h} className={classes.time}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1.2rem' }}
                    width={36}
                    mb={2}
                  />
                </Box>
              ) : (
                <div
                  key={`${h}:00`}
                  data-testid={`timetable-time-${h}`}
                  className={classes.time}
                >{`${h}:00`}</div>
              )
            )}
          </div>
          <div
            style={{
              visibility:
                !initialized || (loading && !config.units?.length)
                  ? 'hidden'
                  : 'visible'
            }}
            ref={elementsRef}
            className={classes.elements}
            onClick={e => handleSlotClick(e)}
          >
            <canvas ref={canvasRef} />
            {/* Make sure we only render events on client side because of date time*/}
            {Object.keys(data).map(entity =>
              data[entity].map(element =>
                // Check if Element is not all-day
                element.units?.length
                  ? getElementDates(element).map((date, index) => {
                      const style = {
                        left:
                          (getUnitIndex(
                            element.units.sort((a, b) => a.order - b.order)[0]
                          ) /
                            config.units.length) *
                            config.slotWidth *
                            config.units.length +
                          config.offset,
                        top:
                          new Date(date.start).getHours() * config.slotHeight +
                          ((Math.floor(new Date(date.start).getMinutes() / 5) *
                            5) /
                            MINUTES) *
                            config.slotHeight +
                          config.offset,
                        height: date.allDay
                          ? 48 * config.slotHeight
                          : ((new Date(date.end) - new Date(date.start)) /
                              1000 /
                              MINUTES /
                              MINUTES) *
                            config.slotHeight,
                        width:
                          element.units.length * config.slotWidth -
                          config.slotPadding,
                        zIndex: config.units.length - element.units.length,
                        justifyContent: (() => {
                          if (
                            new Date(date.start).getHours() <
                            timeStringToHourDecimal(props.showFrom)
                          ) {
                            return 'flex-end';
                          }

                          if (
                            new Date(date.end).getHours() >
                            timeStringToHourDecimal(props.showTill)
                          ) {
                            return 'flex-start';
                          }

                          return 'center';
                        })()
                      };

                      return (
                        <div
                          key={`${element.id}-${convertToString(
                            new Date(date.start),
                            {
                              withTime: false
                            }
                          )}`}
                          onMouseDown={e =>
                            elementHandler(e, element, date, entity)
                          }
                          data-dateseries={element.date_series_id}
                          data-event={element.id}
                          data-date={index}
                          data-testclass="time-table-event"
                          className={`${classes.element} ${
                            props.permissions?.elementClick(element, entity) &&
                            props.onElementClick
                              ? classes.hoverable
                              : null
                          } ${props.customize?.element?.className(
                            element,
                            entity
                          )}`}
                          style={style}
                          title={
                            props.customize?.element?.title
                              ? props.customize?.element?.title(element, entity)
                              : undefined
                          }
                        >
                          {props.customize?.element?.label(
                            element,
                            entity,
                            style
                          )}
                          {!element.locked &&
                          props.onResizeUnits &&
                          props.permissions?.resizeUnits(element, entity) ? (
                            <div
                              className={classes.unitsResize}
                              onMouseDown={e =>
                                resizeUnits(e, element, date, entity)
                              }
                            />
                          ) : null}
                          {!element.locked &&
                          props.onResizeDateEnd &&
                          props.permissions?.resizeDateEnd(element, entity) ? (
                            <div
                              className={classes.dateEndResize}
                              onMouseDown={e =>
                                resizeDateEnd(e, element, date, entity)
                              }
                            />
                          ) : null}
                        </div>
                      );
                    })
                  : null
              )
            )}
          </div>
          {clickDummy}
        </div>
      </div>
    </div>
  );
}

TimeTableDay.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.shape({
    dates: PropTypes.array,
    closures: PropTypes.array,
    holidays: PropTypes.array,
    public_holidays: PropTypes.array
  }),
  loading: PropTypes.bool,
  domains: PropTypes.array.isRequired,
  customize: PropTypes.shape({
    element: PropTypes.shape({
      className: PropTypes.func,
      label: PropTypes.func
    }),
    domainLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    groupLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    style: PropTypes.shape({
      offset: PropTypes.number,
      lineWidth: PropTypes.number,
      strokeStyle: PropTypes.string,
      textColor: PropTypes.string,
      thinLineWidth: PropTypes.number,
      timesOffset: PropTypes.number
    })
  }),
  permissions: PropTypes.shape({
    slotClick: PropTypes.func,
    elementClick: PropTypes.func,
    dragAndDrop: PropTypes.func,
    resizeDateEnd: PropTypes.func,
    resizeUnits: PropTypes.func
  }),
  onSlotClick: PropTypes.func,
  onElementClick: PropTypes.func,
  onDragAndDrop: PropTypes.func,
  onResizeDateEnd: PropTypes.func,
  showFrom: PropTypes.oneOf([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22
  ]),
  showTill: PropTypes.oneOf([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24
  ])
};

TimeTableDay.defaultProps = {
  loading: true,
  day: new Date(),
  data: {
    dates: [],
    closures: [],
    holidays: [],
    public_holidays: []
  },
  domains: [],
  // onSlotClick: (element, { units, date }) => {},
  onSlotClick: undefined,
  // onElementClick: (element, { units, event }) => {},
  onElementClick: undefined,
  // onDragAndDrop: ({ before, after }) => {},
  onDragAndDrop: undefined,
  // onDragAndDrop: ({ before, after }) => {},
  onResizeDateEnd: undefined,
  permissions: {
    slotClick: e => true,
    elementClick: (element, entity) => true,
    dragAndDrop: (element, entity) => true,
    resizeDateEnd: (element, entity) => true,
    resizeUnits: (element, entity) => true
  },
  showFrom: 8,
  showTill: 20,
  slotWidth: 32,
  customize: {
    element: {
      className: (event, entity) => undefined,
      label: (event, entity) => event.booking_name,
      title: (event, entity) => undefined
    },
    domainLabel: domain => domain.name,
    groupLabel: group => group.name,
    style: null
  }
};
