import { getNextWeekday, isBefore, addDays } from './index';

/**
 * Get dates
 */
export default function getDateSeries(start, end, weekday, cycle) {
  let date = getNextWeekday(start, weekday);

  // Set start date to second weekday in month.
  if (cycle === 'monthly_second') {
    date = getNextWeekday(start, weekday);
    date = addDays(date, 1);

    while (date.getDay() !== weekday) {
      date = addDays(date, 1);
    }
  }

  // Set start date to third weekday in month.
  if (cycle === 'monthly_third') {
    date = getNextWeekday(start, weekday);

    for (let i = 0; i < 2; i++) {
      date = addDays(date, 1);

      while (date.getDay() !== weekday) {
        date = addDays(date, 1);
      }
    }
  }

  // Set start date to fourth weekday in month.
  if (cycle === 'monthly_fourth') {
    date = getNextWeekday(start, weekday);

    for (let i = 0; i < 3; i++) {
      date = addDays(date, 1);

      while (date.getDay() !== weekday) {
        date = addDays(date, 1);
      }
    }
  }

  // Set start date to monday if is saturday or sunday
  if (cycle === 'daily_mo_to_fr') {
    if (date.getDay() === 6 || date.getDay() === 0) {
      date = getNextWeekday(start, 1);
    }
  }

  // Set start date to monday if sunday
  if (cycle === 'daily_mo_to_sa') {
    if (date.getDay() === 0) {
      date = getNextWeekday(start, 1);
    }
  }

  let increment;
  const dates = [];

  const getNthDayOfMonthIncrement = (date, nth) => {
    const month = date.getMonth();

    let tmpDate = date;
    let counter = 0;

    while (tmpDate.getMonth() === month) {
      tmpDate = addDays(tmpDate, 1);
      counter += 1;
    }

    for (let i = 0; i < nth; i++) {
      while (tmpDate.getDay() !== weekday) {
        tmpDate = addDays(tmpDate, 1);
        counter += 1;
      }

      if (i < nth - 1) {
        tmpDate = addDays(tmpDate, 1);
        counter += 1;
      }
    }

    return counter;
  };

  while (isBefore(date, end)) {
    dates.push(date);

    switch (cycle) {
      case 'daily':
        increment = 1;

        break;
      case 'daily_mo_to_fr':
        increment = 1;

        if (date.getDay() === 5) {
          increment += 2;
        }

        if (date.getDay() === 6) {
          increment += 1;
        }

        break;
      case 'daily_mo_to_sa':
        increment = 1;

        if (date.getDay() === 6) {
          increment += 1;
        }

        break;
      case 'weekly':
        increment = 7;
        break;
      case 'bi_weekly':
        increment = 14;
        break;
      case 'monthly':
        increment = 28;
        break;
      case 'monthly_first':
        increment = getNthDayOfMonthIncrement(date, 1);
        break;
      case 'monthly_second':
        increment = getNthDayOfMonthIncrement(date, 2);
        break;
      case 'monthly_third':
        increment = getNthDayOfMonthIncrement(date, 3);
        break;
      case 'monthly_fourth':
        increment = getNthDayOfMonthIncrement(date, 4);
        break;
      default:
    }

    date = addDays(date, increment);
  }

  return dates;
}
