import { AxiosResponse } from 'axios';
import { NormalizedSchema } from 'normalizr';
import { Pagination } from '../interfaces';

export default function paginate(
  normalized: NormalizedSchema<{ [key: string]: { [key: string]: any } }, any>,
  response: AxiosResponse<any, any>
): NormalizedSchema<{ [key: string]: { [key: string]: any } }, any> {
  if (normalized.result) {
    normalized.entities.pagination = {
      page: response.data.page_number,
      page_size: response.data.page_size,
      page_ids:
        // Enhance backend pagination behavior
        // If we request the backend with a page larger than total_pages
        // the response is the last page data.
        // This is bad behavior. At this point we empty the reponse page data
        response?.config?.params?.page &&
        Number(response.config.params.page) !==
          Number(response.data.page_number)
          ? []
          : Array.isArray(normalized.result)
          ? normalized.result
          : [normalized.result],
      total_pages: response.data.total_pages,
      total_entries: response.data.total_entries
    };
  }

  return normalized;
}
