import isDate from './isDate';
import timeConvertToString from '../time/convertToString';

/**
 * Converts a date to a string
 */
export default function convertToString(
  dt: Date,
  options: {
    lang?: string;
    withDay?: boolean;
    withTime?: boolean;
    withYear?: boolean;
    multiLine?: boolean;
  } = {
    lang: 'de-DE',
    withDay: true,
    withTime: true,
    withYear: true,
    multiLine: false
  }
) {
  if (!isDate(dt)) return '';

  const language = !!options.lang ? options.lang : 'de-DE';

  const weekday = new Intl.DateTimeFormat(language, {
    weekday: 'short'
  }).format(dt);
  const day = new Intl.DateTimeFormat(language, { day: 'numeric' }).format(dt);
  const month = new Intl.DateTimeFormat(language, {
    month: 'short'
  }).format(dt);
  const year = new Intl.DateTimeFormat(language, { year: 'numeric' }).format(
    dt
  );

  let dateString = '';

  if (options.multiLine) {
    dateString += `${weekday},\n${day}. ${month}`;
  } else {
    dateString += `${weekday}, ${day}. ${month}`;
  }

  if (options.withYear) {
    dateString += ` ${year}`;
  }

  if (options.withTime) {
    if (options.multiLine) {
      dateString += `\n${timeConvertToString(dt, language).pretty}`;
    } else {
      dateString += ` ${timeConvertToString(dt, language).pretty}`;
    }
  }

  if (options.withDay === false) {
    dateString = dateString.replace(/.*, /, '');
  }

  return dateString;
}
