import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { publicHolidaysEntity } from '../schemas';
import { PublicHoliday, Pagination } from '../interfaces';
import { fetchOrganization, fetchOrganizations } from './organization.slice';
import { RootState } from '.';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { paginate, safeArray } from '../utils';

export const fetchPublicHoliday = createAsyncThunk(
  'publicHolidays/byId',
  async publicHolidayId => {
    const response = await client.get(`/public_holidays/${publicHolidayId}`);
    const normalized = normalize(response.data.data, publicHolidaysEntity);

    return normalized.entities;
  }
);

export const fetchPublicHolidays = createAsyncThunk(
  'publicHolidays/all',
  async (filter: Pagination) => {
    const response = await client.get('/public_holidays', filter);

    const normalized = normalize(response.data.data, [publicHolidaysEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createPublicHoliday = createAsyncThunk(
  'publicHolidays/create',
  async publicHoliday => {
    const response = await client.post('/public_holidays', {
      public_holiday: publicHoliday
    });
    const normalized = normalize(response.data.data, publicHolidaysEntity);

    return normalized.entities;
  }
);

export const updatePublicHoliday = createAsyncThunk(
  'publicHolidays/update',
  async ({
    id,
    publicHoliday
  }: {
    id: string;
    publicHoliday: PublicHoliday;
  }) => {
    const response = await client.put(`/public_holidays/${id}`, {
      public_holiday: publicHoliday
    });
    const normalized = normalize(response.data.data, publicHolidaysEntity);

    return normalized.entities;
  }
);

export const archivePublicHoliday = createAsyncThunk(
  'publicHolidays/archive',
  async (id: number) => {
    await client.put(`/archive/public_holidays/${id}`);
    return id;
  }
);

export const unarchivePublicHoliday = createAsyncThunk(
  'publicHolidays/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/public_holidays/${id}`);
    return id;
  }
);

export const deletePublicHoliday = createAsyncThunk(
  'publicHolidays/delete',
  async (id: number) => {
    await client.delete(`/public_holidays/${id}`);
    return id;
  }
);

const publicHolidayAdapter = createEntityAdapter<PublicHoliday>();
const initialState = publicHolidayAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const publicHolidaySlice = createSlice({
  name: 'publicHolidays',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      publicHolidayAdapter.upsertMany(
        state,
        safeArray(payload.publicHolidays.entities)
      );
      state.isLoading = false;
      state.pagination = payload.publicHolidays.pagination;
    });
    builder.addCase(fetchPublicHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPublicHoliday.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
      state.isLoading = false;
    });
    builder.addCase(fetchPublicHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchPublicHolidays.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPublicHolidays.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchPublicHolidays.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createPublicHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createPublicHoliday.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
      state.isLoading = false;
    });
    builder.addCase(createPublicHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePublicHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePublicHoliday.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
      state.isLoading = false;
    });
    builder.addCase(updatePublicHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(archivePublicHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(archivePublicHoliday.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.removeOne(state, payload);
      state.isLoading = false;
    });
    builder.addCase(archivePublicHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deletePublicHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletePublicHoliday.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.removeOne(state, payload);
      state.isLoading = false;
    });
    builder.addCase(deletePublicHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganization.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      publicHolidayAdapter.upsertMany(state, safeArray(payload.publicHolidays));
    });
  }
});

export const publicHolidaySelectors =
  publicHolidayAdapter.getSelectors<RootState>(state => state.publicHolidays);

export default publicHolidaySlice.reducer;
