import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Skeleton, Table as MuiTable } from '@mui/material';
import { TableBody as MuiTableBody } from '@mui/material';
import { TableCell as MuiTableCell } from '@mui/material';
import { TableContainer as MuiTableContainer } from '@mui/material';
import { TableHead as MuiTableHead } from '@mui/material';
import { Collapse as MuiCollapse } from '@mui/material';
import { TableRow as MuiTableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import Dropdown from './Dropdown';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    position: 'relative',
    minWidth: '100%',
    width: 'min-content',
    maxWidth: '100%'
  },
  table: {
    width: props => (props.fullWidth ? '100%' : 'auto'),
    fontSize: '0.9em',
    borderSpacing: 0,
    '& thead': {
      color: theme.color.text.main,
      background: theme.color.background.default
    },
    '& th': {
      color: theme.color.text.main,
      fontWeight: '600',
      padding: '1em .5em',
      border: 'none'
    },
    '& tbody': {
      '& tr': {
        background: 'transparent',
        transition: 'background-color 100ms linear'
      },
      '& tr:hover': {
        background: theme.color.background.grey
      },
      '& tr:last-child td': {
        border: 'none'
      },
      '& td': {
        color: theme.color.text.main,
        padding: '1em .5em',
        verticalAlign: props => props.verticalAlign
      }
    }
  },
  subContent: {
    '&:hover': {
      background: `${theme.color.background.default} !important`
    }
  },
  tableHead: {
    display: 'flex',
    whiteSpace: 'nowrap',
    '&:hover $dropDownButton': {
      opacity: 1
    }
  },
  tableHeadHidden: {
    position: 'absolute',
    width: 1,
    height: 1,
    overflow: 'hidden'
  },
  headCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  sortableHeadCell: {
    '&:hover': {
      color: theme.color.background.primary,
      cursor: 'pointer'
    }
  },
  dropDownButton: {
    opacity: 0
  }
}));

function getHeadCell(column, classes) {
  return (
    <Box
      onClick={column.sortFn}
      className={`${classes.headCell} ${
        column.sortFn != null ? classes.sortableHeadCell : ''
      } ${column.labelHidden ? classes.tableHeadHidden : ''}`}
    >
      {column.label}
    </Box>
  );
}

function getBodyCell(row, column) {
  if (!!column.render) {
    return column.render(row);
  }

  if (!!row[column.id]) {
    return row[column.id];
  }

  return null;
}

function getTableHead(columns, headerOptions, classes) {
  return (
    <MuiTableHead>
      <MuiTableRow>
        {columns.map((column, index) => {
          return (
            <MuiTableCell
              width={column.width}
              key={`table-header-column-${index}`}
            >
              <Box className={classes.tableHead}>
                {getHeadCell(column, classes)}{' '}
                {headerOptions && (
                  <Dropdown
                    onOpen={() => console.log('onOpen')}
                    onClose={() => console.log('onClose')}
                    entries={headerOptions}
                  >
                    <Box ml={0.5}>
                      <Button
                        icon
                        size="small"
                        className={classes.dropDownButton}
                      >
                        more
                      </Button>
                    </Box>
                  </Dropdown>
                )}
              </Box>
            </MuiTableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
}

function getTableBody(rows, columns, classes, loading) {
  const [collapsedRow, setCollapsedRow] = useState();

  return (
    <MuiTableBody style={{ visibility: loading ? 'hidden' : 'visible' }}>
      {rows.map((row, index) => (
        <React.Fragment key={`table-body-row-${index}`}>
          <MuiTableRow title={row.title}>
            {row?.subContent !== undefined && (
              <MuiTableCell>
                {collapsedRow !== undefined && collapsedRow === index ? (
                  <Button
                    // TBD: Intl
                    aria-label="Untergeordnete Tabelle öffnen"
                    icon
                    onClick={() => setCollapsedRow()}
                  >
                    arrow-up
                  </Button>
                ) : (
                  <Button
                    // TBD: Intl
                    aria-label="Untergeordnete Tabelle schließen"
                    icon
                    onClick={() => setCollapsedRow(index)}
                  >
                    arrow-right
                  </Button>
                )}
              </MuiTableCell>
            )}
            {columns
              .filter(c => c.id !== 'subContentExpander')
              .map((column, index) => {
                return (
                  <MuiTableCell
                    style={
                      row?.deactivatedTableRow
                        ? {
                            border: loading ? 0 : null,
                            textDecoration: 'line-through'
                          }
                        : { border: loading ? 0 : null }
                    }
                    key={`table-body-row-column-${index}`}
                  >
                    {getBodyCell(row, column)}
                  </MuiTableCell>
                );
              })}
          </MuiTableRow>
          {row?.subContent !== undefined && (
            <MuiTableRow className={classes.subContent}>
              <MuiTableCell
                colSpan={columns.length}
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                <MuiCollapse
                  in={collapsedRow === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>{row.subContent}</Box>
                </MuiCollapse>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </React.Fragment>
      ))}
    </MuiTableBody>
  );
}

const Table = React.forwardRef(function Tabke(props, ref) {
  const classes = useStyles(props);
  // Check if any row has sub content
  const hasSubContent = props.rows?.filter(r => r && r.subContent)?.length > 0;
  // If sub content exist, make sure we add an empty column for expander icon
  const columns = hasSubContent
    ? [
        {
          id: 'subContentExpander',
          // TBD: intl
          label: 'Untergeordnete Tabelle öffen/schließen',
          labelHidden: true
        }
      ].concat(props.columns)
    : props.columns;

  return (
    <MuiTableContainer ref={ref} className={classes.tableWrapper} id={props.id}>
      <MuiTable
        className={classes.table}
        aria-label={props.label || 'table'}
        data-testid={props['data-testid']}
      >
        {getTableHead(columns, props.headerOptions, classes)}
        {props.loading && (
          <MuiTableBody data-testid="loading-indicator">
            {[...Array(props.expectedLength).keys()].map(i => (
              <MuiTableRow key={i}>
                {columns.map((c, j) => (
                  <MuiTableCell key={j}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                      width={c.width ? (parseFloat(c.width) / 100) * 512 : 48}
                    />
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            ))}
          </MuiTableBody>
        )}
        {getTableBody(props.rows, columns, classes, props.loading)}
      </MuiTable>
    </MuiTableContainer>
  );
});

Table.propTypes = {
  id: PropTypes.string,
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  expectedLength: PropTypes.number,
  headerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  'data-testid': PropTypes.string
};

Table.defaultProps = {
  verticalAlign: 'top',
  expectedLength: 10,
  fullWidth: false,
  loading: false,
  headerOptions: undefined
};

export default Table;
