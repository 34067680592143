import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

import Drawer from './Drawer';
import Icon from './Icon';
import Hoverbox from './Hoverbox';

const useStyles = makeStyles(theme => ({
  drawerItemLogo: {
    height: '1.125em',
    width: '1.125em',
    alignItems: 'center',
    userSelect: 'none'
  },
  drawerItem: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    userSelect: 'none',
    transition: 'background-color 100ms linear',
    backgroundColor: ({ props, state }) =>
      props.active ? theme.color.background.grey : theme.color.common.white,
    borderTop: ({ props, state }) =>
      props.active
        ? `1px solid ${theme.color.border.light}`
        : `1px solid ${theme.color.background.default}`,
    borderBottom: ({ props, state }) =>
      props.active
        ? `1px solid ${theme.color.border.light}`
        : `1px solid ${theme.color.background.default}`,
    '&:hover': {
      backgroundColor: ({ props, state }) => theme.color.background.grey,
      borderTop: ({ props, state }) => `1px solid ${theme.color.border.light}`,
      borderBottom: ({ props, state }) =>
        `1px solid ${theme.color.border.light}`
    },
    '&:focus': {
      backgroundColor: ({ props }) => theme.color.background.grey,
      outline: 'none'
    }
  },
  drawerItemText: {
    display: 'flex',
    flex: 1,
    userSelect: 'none',
    gap: theme.spacing(1),
    lineHeight: 0.8,
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: theme.color.text.main,
    fontWeight: 600,
    fontSize: '.8em',
    cursor: 'pointer',
    '& svg': {
      width: '1rem',
      height: '1rem'
    }
  },
  underline: {
    width: '100%',
    height: '0.0625em',
    background: theme.color.background.grey
  },
  subitems: {
    '& a': {
      textDecoration: 'none',
      color: theme.color.text.main,
      fontWeight: 600,
      fontSize: '1em',
      cursor: 'pointer'
    }
  }
}));

export default function DrawerItem(props) {
  const drawerContext = useContext(Drawer.Context);
  const onClick = drawerContext.onClick;
  const visible = drawerContext.visible;

  const [isHovered, setIsHovered] = useState(false);
  const [subMenuIndices, setSubMenuIndices] = useState([]);
  const [active, setActive] = useState(
    (props.index === drawerContext.currentIndex ||
      subMenuIndices.includes(drawerContext.currentIndex) ||
      props.active) &&
      visible
  );
  const classes = useStyles({ props, state: { isHovered, visible } });

  function handleClick() {
    if (props.index === 'openclose') {
      props.onClick();
    }

    if (props.children?.length) {
      setActive(!active);
    }
  }

  const handleOnKeyDown = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  function drawerItem() {
    return (
      <>
        <Box
          component={visible && props.children?.length ? null : props.component}
          display="flex"
          width={1}
          className={classes.drawerItem}
          p={1.7}
          alignItems="center"
          onClick={
            props.index === 'openclose' || props.children?.length
              ? handleClick
              : null
          }
          tabIndex={0}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onKeyDown={handleOnKeyDown}
          href={visible && props.children?.length ? null : props.href}
          aria-label={props['aria-label']}
        >
          <Box display="flex" className={classes.drawerItemLogo}>
            {props.icon && <Icon color="text" name={props.icon} />}
          </Box>
          {visible && (
            <>
              <Box className={classes.drawerItemText} pl={1.5}>
                {props.title}
                {props.children?.length ? (
                  <Icon
                    color="text"
                    name={active ? 'arrow-down' : 'arrow-right'}
                  />
                ) : null}
              </Box>
            </>
          )}
        </Box>
        {active && visible ? props.children : null}
      </>
    );
  }

  return (
    <>
      {!(props.index === 'openclose' || visible) ? (
        <Box display="flex">
          <Hoverbox
            id={`hover-${props.index}`}
            mode="hover"
            target={drawerItem()}
            position="right-start"
            borderless
            width="10em"
            fullWidthTarget
          >
            <Box className={classes.subitems}>
              <Drawer.SubItem
                component={props.component}
                href={props.href}
                // Uncomment to make drawer 'category' behave like a disabled item (no hover, pointer effects)
                // disabled
                index={props.index}
                title={props.title}
              />
              <div className={classes.underline} />
              {props.children}
            </Box>
          </Hoverbox>
        </Box>
      ) : (
        drawerItem()
      )}
    </>
  );
}

DrawerItem.defaultProps = {
  children: null,
  active: false,
  // onClick: () => {},
  icon: null,
  title: '',
  wrapper: children => children
};

DrawerItem.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  wrapper: PropTypes.func
};
