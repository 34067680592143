import React from 'react';
import { Box } from '@mui/system';

import FormGroup from '../FormGroup';
import FormatBoolean from '../FormatBoolean';
import FormatNumber from '../FormatNumber';
import Heading from '../Heading';
import InformationGrid from '../InformationGrid';
import MultiToggle from '../MultiToggle';
import NumberField from '../NumberField';
import Spacing from '../Spacing';
import TextField from '../TextField';
import Typeahead from '../Typeahead';

import jsonStrings from '../../utils/intl/jsonStrings';

const FIELD_ID_PREFIX = 'jsonField';

const FIELDS__REQUIRED_FIELD_NAME = 'Pflichtfeld in der UI? (create / edit)';
const FIELDS__DATA_TYPE_FIELD_NAME = 'mögl. Datentyp';
const FIELDS__INTERNAL_FIELD_NAME = 'Exponiert  im Nutzerportal?';
const FIELDS__CATEGORY_FIELD_NAME = 'Objekt';
const FIELDS__VALUE_FIELD_NAME = 'value';

const DATA_TYPE__ENUM = 'Aufzählung';
const DATA_TYPE__BOOLEAN = 'Boolean';
const DATA_TYPE__NUMBER = 'Ganzzahl';
const DATA_TYPE__FLOAT = 'Gleitpunkt';
const DATA_TYPE__TEXT_5 = 'Text(5)';
const DATA_TYPE__TEXT_25 = 'Text(25)';
const DATA_TYPE__TEXT_80 = 'Text(80)';
const DATA_TYPE__TEXT_255 = 'Text(255)';
const DATA_TYPE__TEXT_1000 = 'Text(1000)';
const DATA_TYPE__MULTI_LINE_TEXT_1000 = 'Multiline-Text(1000)';
const DATA_TYPE__MULTI_LINE_TEXT_4000 = 'Multiline-Text(4000)';

const ENUM_ITEM__FACILITY_TYPE = 'Anlagentyp';
const ENUM_ITEM__CATEGORY = 'Kategorie';
const ENUM_ITEM__FLOORING = 'Bodenbelagtyp';

const BOOLEAN_YES_STRINGS = ['j', 'ja', 'y', 'yes', 't', 'true', true];
const BOOLEAN_NO_STRINGS = ['n', 'nein', 'no', 'f', 'false', false];

const YES = 'ja';
const NO = 'nein';

const searchGroupTypes = (searchTerm, groupTypes) =>
  groupTypes.map(type => type.includes(searchTerm));

const searchGroupFloorings = (searchTerm, groupFloorings) =>
  groupFloorings.map(flooring => flooring.includes(searchTerm));

const searchGroupCoverages = (searchTerm, groupCoverages) =>
  groupCoverages.map(coverage => coverage.includes(searchTerm));

export function unique(value, index, self) {
  return self.indexOf(value) === index;
}

function transformBooleanToJsonFieldTextValue(value) {
  if (value === true) {
    return YES;
  }

  if (value === false) {
    return NO;
  }

  return null;
}

function transformJsonFieldToBooleanValue(value) {
  if (BOOLEAN_YES_STRINGS.includes(value?.toLowerCase())) {
    return true;
  }

  if (BOOLEAN_NO_STRINGS.includes(value?.toLowerCase())) {
    return false;
  }

  return null;
}

export function setJsonFieldValues(jsonFields, values) {
  const updatedJsonFields = { ...jsonFields };

  if (jsonFields && values && values[FIELD_ID_PREFIX]) {
    for (let field in jsonFields) {
      if (values[FIELD_ID_PREFIX]?.hasOwnProperty(field)) {
        let value = values[FIELD_ID_PREFIX][field];

        if (
          jsonFields[field][FIELDS__DATA_TYPE_FIELD_NAME] === DATA_TYPE__BOOLEAN
        ) {
          value = transformBooleanToJsonFieldTextValue(value);
        }

        updatedJsonFields[field] = {
          ...jsonFields[field],
          value
        };
      } else {
        console.warn(
          `JSONFields: setJsonFieldValues could not find field '${FIELD_ID_PREFIX}.${field}' in values`
        );
      }
    }
  }

  return updatedJsonFields;
}

export function getJsonFieldValues(jsonFields = {}) {
  const result = {
    jsonFieldsTemplate: jsonFields,
    [FIELD_ID_PREFIX]: {}
  };

  if (jsonFields) {
    Object.keys(jsonFields).forEach(k => {
      const type = jsonFields[k][FIELDS__DATA_TYPE_FIELD_NAME];
      const value = jsonFields[k][FIELDS__VALUE_FIELD_NAME];

      result[FIELD_ID_PREFIX][k] =
        type === DATA_TYPE__BOOLEAN
          ? transformJsonFieldToBooleanValue(value)
          : value;
    });
  }

  return result;
}

export function getJsonFieldInputComponent(
  value,
  item,
  type,
  required,
  props,
  intl
) {
  switch (type) {
    case DATA_TYPE__ENUM:
      return getJsonFieldInputEnum(value, item, required, type, props, intl);

    case DATA_TYPE__BOOLEAN:
      return getJsonFieldInputBoolean(value, item, required, props, intl);

    case DATA_TYPE__NUMBER:
      return getJsonFieldInputNumeric(value, item, required, props, 0);

    case DATA_TYPE__FLOAT:
      return getJsonFieldInputNumeric(value, item, required, props, 17);

    case DATA_TYPE__TEXT_5:
      return getJsonFieldInputString(value, item, required, props, 5, false);

    case DATA_TYPE__TEXT_25:
      return getJsonFieldInputString(value, item, required, props, 25, false);

    case DATA_TYPE__TEXT_80:
      return getJsonFieldInputString(value, item, required, props, 80, false);

    case DATA_TYPE__TEXT_255:
      return getJsonFieldInputString(value, item, required, props, 255, false);

    case DATA_TYPE__TEXT_1000:
      return getJsonFieldInputString(value, item, required, props, 1000, false);

    case DATA_TYPE__MULTI_LINE_TEXT_1000:
      return getJsonFieldInputString(value, item, required, props, 1000, true);

    case DATA_TYPE__MULTI_LINE_TEXT_4000:
      return getJsonFieldInputString(value, item, required, props, 4000, true);

    default:
      console.error(`Unknown json field data type: ${type}`);
      return null;
  }
}

export function getJsonFieldInputEnum(
  value,
  item,
  type,
  required,
  props,
  intl
) {
  if (type === DATA_TYPE__ENUM) {
    if (item === ENUM_ITEM__FACILITY_TYPE) {
      return (
        <>
          <Spacing y={0.5} />
          <Typeahead
            id={`${FIELD_ID_PREFIX}.${item}`}
            label={item}
            disabled={props.disabled}
            fullWidth
            onInputChange={term => searchGroupTypes(term, props.groupTypes)}
            options={GroupTypes}
            getOptionLabel={option =>
              jsonStrings[`group_type_${option}`]
                ? intl.formatMessage(jsonStrings[`group_type_${option}`])
                : option
            }
            isOptionEqualToValue={(option, value) => option === value}
            // required={required}
          />
        </>
      );
    }

    if (item === ENUM_ITEM__CATEGORY) {
      return (
        <>
          <Spacing y={0.5} />
          <Typeahead
            id={`${FIELD_ID_PREFIX}.${item}`}
            label={item}
            disabled={props.disabled}
            fullWidth
            onInputChange={term =>
              searchGroupCoverages(term, props.groupCoverages)
            }
            options={GroupCoverages}
            getOptionLabel={option =>
              jsonStrings[option]
                ? intl.formatMessage(jsonStrings[option])
                : option
            }
            isOptionEqualToValue={(option, value) => option === value}
            // required={required}
          />
        </>
      );
    }

    if (item === ENUM_ITEM__FLOORING) {
      return (
        <>
          <Spacing y={0.5} />
          <Typeahead
            id={`${FIELD_ID_PREFIX}.${item}`}
            label={item}
            disabled={props.disabled}
            fullWidth
            onInputChange={term =>
              searchGroupFloorings(term, props.groupFloorings)
            }
            options={GroupFloorings}
            getOptionLabel={option =>
              jsonStrings[`group_flooring_${option}`]
                ? intl.formatMessage(jsonStrings[`group_flooring_${option}`])
                : option
            }
            isOptionEqualToValue={(option, value) => option === value}
          />
        </>
      );
    }
  }
}

export function getJsonFieldInputBoolean(value, item, required, props, intl) {
  return (
    <MultiToggle
      id={`${FIELD_ID_PREFIX}.${item}`}
      label={item}
      disabled={props.disabled}
      options={[
        {
          title: intl.formatMessage(jsonStrings['yes']),
          index: true
        },
        ...(required
          ? []
          : [
              {
                title: intl.formatMessage(jsonStrings['undefined']),
                index: null
              }
            ]),
        {
          title: intl.formatMessage(jsonStrings['no']),
          index: false
        }
      ]}
      // required={required}
    />
  );
}

export function getJsonFieldInputNumeric(
  value,
  item,
  required,
  props,
  decimalScale
) {
  return (
    <>
      <Spacing y={0.5} />
      <NumberField
        id={`${FIELD_ID_PREFIX}.${item}`}
        label={item}
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={decimalScale}
        // required={required}
      />
    </>
  );
}

export function getJsonFieldInputString(
  value,
  item,
  required,
  props,
  maxLength,
  multiLine
) {
  return (
    <>
      <Spacing y={0.5} />
      <TextField
        id={`${FIELD_ID_PREFIX}.${item}`}
        name={item}
        label={item}
        disabled={props.disabled}
        fullWidth={maxLength > 5}
        maxLength={maxLength}
        multiline={multiLine}
        // required={required}
        type="text"
      />
    </>
  );
}

export function renderFields(fields, categories, props) {
  const tmpCategories = Object.keys(fields)
    .map(item => fields[item][FIELDS__CATEGORY_FIELD_NAME])
    .filter(unique)
    .sort()
    .map(category => {
      return {
        name: category,
        items: renderCategoryItems(fields, category, props).filter(
          item => !!item
        ),
        order: categories?.filter(c => c.category_name === category)[0]?.order
      };
    })
    .filter(category => category.items?.length > 0)
    .sort((a, b) => a.order - b.order);

  return tmpCategories.map(category => {
    if (category.items.length) {
      return (
        <React.Fragment key={category.name}>
          <InformationGrid
            labelWidth={props.labelWidth}
            title={
              <Heading
                type="h2"
                sideline
                icon={props.disableCategoryIcons ? null : 'info-circle'}
              >
                {category.name}
              </Heading>
            }
            key={category.name}
          >
            {category.items}
          </InformationGrid>
        </React.Fragment>
      );
    }

    return null;
  });
}

export function renderCategoryItems(fields, category, props) {
  return Object.keys(fields)
    .filter(
      item =>
        fields[item][FIELDS__CATEGORY_FIELD_NAME] === category &&
        // Filter out `unset` fields
        fields[item][FIELDS__VALUE_FIELD_NAME]
    )
    .sort((a, b) => fields[a].index - fields[b].index)
    .map(item => {
      const dataType = fields[item][FIELDS__DATA_TYPE_FIELD_NAME];
      const internal = transformJsonFieldToBooleanValue(
        fields[item][FIELDS__INTERNAL_FIELD_NAME]
      );
      let value = fields[item][FIELDS__VALUE_FIELD_NAME];

      if (props.internal || !internal) {
        if (dataType === DATA_TYPE__BOOLEAN) {
          if (BOOLEAN_YES_STRINGS.includes(value)) {
            value = <FormatBoolean>{true}</FormatBoolean>;
          }

          if (BOOLEAN_NO_STRINGS.includes(value)) {
            value = <FormatBoolean>{false}</FormatBoolean>;
          }
        } else if (
          dataType === DATA_TYPE__FLOAT ||
          dataType === DATA_TYPE__NUMBER
        ) {
          if (value) {
            value = <FormatNumber>{value}</FormatNumber>;
          }
        }

        // TODO: Remove temporary formatting of `Interne Vermerke` field data.
        if (
          dataType.includes('Multiline') &&
          !value?.toString().includes('\n') &&
          value?.toString().includes(';') &&
          value?.toString().includes('•')
        ) {
          value = value.replaceAll('•', '• ');
          value = value.replaceAll(';', '\n');
        }

        return (
          <InformationGrid.Item value={item} key={item}>
            {value}
          </InformationGrid.Item>
        );
      }

      return null;
    });
}

export function renderEditFields(fields, categories, props, intl) {
  if (fields) {
    const tmpCategories = Object.keys(fields)
      .map(item => fields[item][FIELDS__CATEGORY_FIELD_NAME])
      .filter(unique)
      .sort()
      .map(category => {
        return {
          name: category,
          items: renderEditCategoryItems(fields, category, props, intl),
          order: categories?.filter(c => c.category_name === category)[0]?.order
        };
      })
      .sort((a, b) => a.order - b.order);

    return tmpCategories.map((category, i) => {
      return (
        <FormGroup
          expectedInputs={category.items?.length}
          title={category.name}
          key={category.name}
          index={
            Number.isInteger(props.startIndex) ? props.startIndex + i : null
          }
          expandable={
            props.expandable &&
            props.expandableCategories?.filter(c => c.name === category.name)[0]
              ?.changedFields?.length === 0
          }
        >
          {category.items}
        </FormGroup>
      );
    });
  }
}

export function renderEditCategoryItems(fields, category, props, intl) {
  return Object.keys(fields)
    .filter(item => fields[item][FIELDS__CATEGORY_FIELD_NAME] === category)
    .sort((a, b) => fields[a].index - fields[b].index)
    .map(item => {
      const dataType = fields[item][FIELDS__DATA_TYPE_FIELD_NAME];
      const required = transformJsonFieldToBooleanValue(
        fields[item][FIELDS__REQUIRED_FIELD_NAME]
      );
      const value = fields[item][FIELDS__VALUE_FIELD_NAME];

      return (
        <Box key={item}>
          <Spacing y={2} />
          {getJsonFieldInputComponent(
            value,
            item,
            dataType,
            required,
            props,
            intl
          )}
        </Box>
      );
    });
}
