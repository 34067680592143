import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';

import classNames from '../utils/react/classNames';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.color.border.light}`,
    ...(theme.components?.breadcrumb?.root
      ? theme.components?.breadcrumb?.root
      : {})
  }
}));
export default function Breadcrumb(props) {
  const classes = useStyles(props);

  return (
    <Breadcrumbs
      aria-label={props.ariaLabel}
      aria-hidden={props.ariaHidden}
      separator={props.separator}
      className={classNames([classes.breadcrumbs, props.className])}
    >
      {props.children}
    </Breadcrumbs>
  );
}

Breadcrumb.propTypes = {
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired
};

Breadcrumb.defaultProps = {
  ariaHidden: false,
  ariaLabel: ''
};
