import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { OrganizationMember, Pagination } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { fetchOrganizations } from './organization.slice';
import { organizationMembersEntity } from '../schemas';
import { paginate, safeArray } from '../utils';

export const fetchOrganizationMember = createAsyncThunk(
  'organizationMembers/byId',
  async organizationMemberId => {
    const response = await client.get(
      `/organization_members/${organizationMemberId}`
    );
    const normalized = normalize(response.data.data, organizationMembersEntity);

    return normalized.entities;
  }
);

export const fetchOrganizationMembers = createAsyncThunk(
  'organizationMembers/all',
  async (filter: Pagination) => {
    const response = await client.get('/organization_members', filter);
    const normalized = normalize(response.data.data, [
      organizationMembersEntity
    ]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createOrganizationMember = createAsyncThunk(
  'organizationMembers/create',
  async organizationMember => {
    const response = await client.post('/organization_members', {
      organization_member: organizationMember
    });
    const normalized = normalize(response.data.data, organizationMembersEntity);

    return normalized.entities;
  }
);

export const updateOrganizationMember = createAsyncThunk(
  'organization_members/update',
  async ({
    id,
    organizationMember
  }: {
    id: string;
    organizationMember: OrganizationMember;
  }) => {
    const response = await client.put(`/organization_members/${id}`, {
      organization_member: organizationMember
    });
    const normalized = normalize(response.data.data, organizationMembersEntity);

    return normalized.entities;
  }
);

export const archiveOrganizationMember = createAsyncThunk(
  'organizationMembers/archive',
  async (id: number) => {
    await client.put(`/archive/organization_members/${id}`);
    return id;
  }
);

export const unarchiveOrganizationMember = createAsyncThunk(
  'organizationMembers/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/organization_members/${id}`);
    return id;
  }
);

export const deleteOrganizationMember = createAsyncThunk(
  'organization_members/delete',
  async (id: number) => {
    await client.delete(`/organization_members/${id}`);
    return id;
  }
);

const organizationMemberAdapter = createEntityAdapter<OrganizationMember>();
const initialState = organizationMemberAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const organizationMemberSlice = createSlice({
  name: 'organizationMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      organizationMemberAdapter.upsertMany(
        state,
        safeArray(payload.organizationMembers.entities)
      );
      state.pagination = payload.organizationMembers.pagination;
    });
    builder.addCase(fetchOrganizationMembers.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchOrganizationMembers.fulfilled,
      (state, { payload }) => {
        organizationMemberAdapter.upsertMany(
          state,
          safeArray(payload.organizationMembers)
        );
        state.pagination = payload.pagination;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchOrganizationMembers.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizationMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrganizationMember.fulfilled, (state, { payload }) => {
      organizationMemberAdapter.upsertMany(
        state,
        safeArray(payload.organizationMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizationMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createOrganizationMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      createOrganizationMember.fulfilled,
      (state, { payload }) => {
        organizationMemberAdapter.upsertMany(
          state,
          safeArray(payload.entities)
        );
        state.isLoading = false;
      }
    );
    builder.addCase(createOrganizationMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateOrganizationMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      updateOrganizationMember.fulfilled,
      (state, { payload }) => {
        organizationMemberAdapter.upsertMany(
          state,
          safeArray(payload.organizationMembers)
        );
        state.isLoading = false;
      }
    );
    builder.addCase(updateOrganizationMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOrganizationMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteOrganizationMember.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOrganizationMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      organizationMemberAdapter.upsertMany(
        state,
        safeArray(payload.organizationMembers)
      );
    });
  }
});

export const organizationMemberSelectors =
  organizationMemberAdapter.getSelectors<RootState>(
    state => state.organizationMembers
  );

export default organizationMemberSlice.reducer;
