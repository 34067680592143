import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { DateSeries, Pagination, UUID } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { convertToLocalString } from 'groundkeeper-component-library';
import {
  createEvent,
  updateEvent,
  fetchEvents,
  deleteEvent
} from './event.slice';
import { dateSeriesEntity } from '../schemas';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { isoDateSort } from '../utils/isoDateSort';
import { safeArray } from '../utils';

export const fetchDateSeries = createAsyncThunk('dateSeries/all', async () => {
  const response = await client.get('/date_series');
  const normalized = normalize(response.data.data, [dateSeriesEntity]);

  return normalized.entities;
});

export const fetchSingleDateSeries = createAsyncThunk(
  'dateSeries/single',
  async (id: string) => {
    const response = await client.get(`/date_series/${id}`);
    const normalized = normalize(response.data.data, dateSeriesEntity);

    return normalized.entities;
  }
);

export const deleteDateSeries = createAsyncThunk(
  'dateSeries/delete',
  async (dateSeries: { id: UUID }) => {
    await client.delete(`/date_series/${dateSeries.id}`);
    const normalized = normalize(dateSeries, dateSeriesEntity);

    return normalized.entities;
  }
);

export const updateDateSeries = createAsyncThunk(
  'dateSeries/update',
  async ({ id, dateSeries }: { id: string; dateSeries: DateSeries }) => {
    const response = await client.put(`/date_series/${id}`, {
      id: id,
      date_series: dateSeries
    });
    const normalized = normalize(response.data.data, dateSeriesEntity);

    return normalized.entities;
  }
);

export const createDateSeries = createAsyncThunk(
  'dateSeries/create',
  async (dateSeries: DateSeries) => {
    const response = await client.post('/date_series', {
      date_series: dateSeries
    });
    const normalized = normalize(response.data.data, dateSeriesEntity);

    return normalized.entities;
  }
);

export const previewDateSeries = createAsyncThunk(
  'dateSeries/preview',
  async ({
    dateSeries,
    filter
  }: {
    dateSeries: DateSeries;
    filter: Pagination;
  }) => {
    const response = await client.post(
      `/date_series_preview`,
      {
        date_series: dateSeries
      },
      {
        status: 'new,accepted',
        conflicts_only: false,
        ...filter
      }
    );

    return response.data.data;
  }
);

export const conflictsDateSeries = createAsyncThunk(
  'dateSeries/conflicts',
  async dateSeries => {
    const response = await client.post('/conflicts', {
      date_series: dateSeries
    });

    return response.data.data;
  }
);

const dateSeriesAdapter = createEntityAdapter<DateSeries>();
const initialState = dateSeriesAdapter.getInitialState({ isLoading: false });
const dateSeriesSlice = createSlice({
  name: 'dateSeries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      dateSeriesAdapter.upsertMany(
        state,
        safeArray(payload.dateSeries.entities)
      );
    });
    builder.addCase(fetchDateSeries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchDateSeries.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
      state.isLoading = false;
    });
    builder.addCase(fetchDateSeries.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchSingleDateSeries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchSingleDateSeries.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
      state.isLoading = false;
    });
    builder.addCase(fetchSingleDateSeries.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createDateSeries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createDateSeries.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
      state.isLoading = false;
    });
    builder.addCase(createDateSeries.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateDateSeries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateDateSeries.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
      state.isLoading = false;
    });
    builder.addCase(updateDateSeries.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteDateSeries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteDateSeries.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.removeMany(
        state,
        Object.values(safeArray(payload.dateSeries)).map(dS =>
          dateSeriesAdapter.selectId(dS)
        )
      );
      state.isLoading = false;
    });
    builder.addCase(deleteDateSeries.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(createEvent.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      dateSeriesAdapter.upsertMany(state, safeArray(payload.dateSeries));
    });
  }
});

export const dateSeriesSelectors = dateSeriesAdapter.getSelectors<RootState>(
  state => state.dateSeries
);

export default dateSeriesSlice.reducer;
