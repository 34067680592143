import PropTypes from 'prop-types';
import React from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import { deDE } from '@mui/material/locale';
import { makeStyles } from '@mui/styles';

interface DefaultTheme {
  color: {
    background: {
      default: string;
      primary: string;
      secondary: string;
      grey: string;
    };
    border: {
      light: string;
      main: string;
      dark: string;
    };
    error: {
      light: string;
      main: string;
      contrastText: string;
    };
    warning: {
      light: string;
      main: string;
    };
    success: string;
    text: {
      light: string;
      main: string;
      dark: string;
      link: string;
    };
    common: {
      white: string;
      black: string;
      grey: {
        light: string;
        main: string;
        dark: string;
      };
    };
    primary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    secondary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  };
  font: {
    primary: string;
    secondary: string;
    size: {
      root: string;
      input: string;
      text: string;
      small: string;
      large: string;
    };
  };
  effect: {
    boxShadow: string;
  };
  borderRadius: {
    small: number;
    medium: number;
    large: number;
  };
  mediaQueries: {
    tiny: string;
    small: string;
    medium: string;
    large: string;
  };
  components: {
    heading: {
      h1: {
        fontSize: string;
      };
      h2: {
        fontSize: string;
        borderLeft: string;
        paddingLeft: string;
      };
    };
    textField: any;
    button: any;
    message: any;
  };
}

declare module '@mui/material/styles' {
  interface Theme extends DefaultTheme {}
  // allow configuration using `createTheme`
  interface ThemeOptions extends DefaultTheme {}
}

export default function ThemeProvider(props) {
  // To make sure the MaterialUI components work as expected we have to
  // wrap our theme with MaterialUI theme
  const overwriteMuiTheme = {
    ...props.theme,
    palette: {
      error: {
        light: props.theme.color.error.main,
        main: props.theme.color.error.main,
        dark: props.theme.color.error.main,
        contrastText: props.theme.color.error.contrastText
      }
    },
    typography: {
      fontFamily: props.theme.font.primary
    }
  };

  return (
    <MuiThemeProvider theme={createTheme(overwriteMuiTheme, deDE)}>
      <GlobalStyleComponent>{props.children}</GlobalStyleComponent>
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.shape({
      background: PropTypes.shape({
        default: PropTypes.string,
        primary: PropTypes.string,
        secondary: PropTypes.string,
        grey: PropTypes.string
      }),
      border: PropTypes.shape({
        light: PropTypes.string,
        main: PropTypes.string,
        dark: PropTypes.string
      }),
      error: PropTypes.shape({
        light: PropTypes.string,
        main: PropTypes.string,
        contrastText: PropTypes.string
      }),
      warning: PropTypes.shape({
        light: PropTypes.string,
        main: PropTypes.string
      }),
      success: PropTypes.string,
      text: PropTypes.shape({
        dark: PropTypes.string,
        main: PropTypes.string,
        main80: PropTypes.string,
        main70: PropTypes.string,
        main60: PropTypes.string,
        link: PropTypes.string
      }),
      common: PropTypes.shape({
        white: PropTypes.string,
        black: PropTypes.string,
        grey: PropTypes.shape({
          light50: PropTypes.string,
          light: PropTypes.string,
          main80: PropTypes.string,
          main: PropTypes.string,
          dark: PropTypes.string,
          dark120: PropTypes.string
        })
      }),
      primary: PropTypes.shape({
        light: PropTypes.string,
        light120: PropTypes.string,
        light140: PropTypes.string,
        light160: PropTypes.string,
        main: PropTypes.string,
        dark: PropTypes.string,
        text: PropTypes.string,
        contrastText: PropTypes.string
      }),
      secondary: PropTypes.shape({
        brash: PropTypes.string,
        light: PropTypes.string,
        main: PropTypes.string,
        dark: PropTypes.string,
        contrastText: PropTypes.string
      })
    }),
    font: PropTypes.shape({
      primary: PropTypes.string,
      secondary: PropTypes.string,
      size: PropTypes.shape({
        root: PropTypes.string,
        input: PropTypes.string,
        text: PropTypes.string,
        small: PropTypes.string,
        large: PropTypes.string
      })
    }),
    effect: PropTypes.shape({
      boxShadow: PropTypes.string
    }),
    borderRadius: PropTypes.shape({
      small: PropTypes.string,
      medium: PropTypes.string,
      large: PropTypes.string
    }),
    mediaQueries: PropTypes.shape({
      tiny: PropTypes.string,
      small: PropTypes.string,
      medium: PropTypes.string,
      large: PropTypes.string
    }),
    // Html id of content element to create scroll listener
    contentId: PropTypes.string,
    contentOffset: PropTypes.string,
    components: PropTypes.object
  }),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
};

ThemeProvider.defaultProps = {
  theme: {
    color: {
      background: {
        default: '#FFFFFF',
        primary: '#4792CD',
        secondary: '#E84F4F',
        grey: '#F7F7F7'
      },
      // For borders
      border: {
        light: '#EBEBEB', // disabled
        main: '#D1D1D1', // standard
        dark: '#969696' // focused
      },
      error: {
        light: '#FCA49D',
        main: '#CF241B',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#fdb81e',
        light: '#F9F4E4'
      },
      success: '#428103',
      text: {
        light: '#757575', // light text
        main: '#303030', // standard text
        dark: '#0A0A0A', // dark Text
        link: '#293B75' // link Text color
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
        // For basic components
        grey: {
          light: '#757575', // disabled
          main: '#595959', // standard
          dark: '#404040' // focused
        }
      },
      primary: {
        // For primary components
        light: '#5472C0', // disabled
        main: '#293B75', // standard
        dark: '#182349', // focused
        contrastText: '#FFFFFF' // text
      },
      secondary: {
        // For secondary components
        light: '#EF7A75', // disabled
        main: '#CF241B', // standard
        dark: '#CD1C15', // focused
        contrastText: '#FFFFFF' // text
      }
    },
    font: {
      primary: 'Open Sans, sans-serif',
      secondary: '',
      size: {
        root: '16px', // base size for following sizes
        input: '.875rem', // input components
        text: '.875rem', // standard text
        small: '.75rem', // small text
        large: '1.125rem' // large text
      }
    },
    effect: {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px'
    },
    borderRadius: {
      small: '.25rem',
      medium: '.5rem',
      large: '.75rem'
    },
    mediaQueries: {
      tiny: '@media (max-width: 37rem)',
      small: '@media (max-width: 64rem)',
      medium: '@media (max-width: 92rem)',
      large: '@media (min-width: 92rem)'
    },
    contentContainer: null
  },
  children: ''
};

const useStyles = makeStyles((theme: DefaultTheme) => ({
  '@global': {
    body: {
      margin: 0
    },
    a: {
      color: theme.color.text.link,
      textDecoration: 'underline'
    }
  },
  theme: {
    fontFamily: theme.font.primary
  }
}));

function GlobalStyleComponent(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.theme}>
      {props.children}
      <div id="gk-component-library-portal" />
    </div>
  );
}

GlobalStyleComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
};

GlobalStyleComponent.defaultProps = {
  children: ''
};
