import { AnyAction } from 'redux';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { fetchConsumer, fetchConsumers } from './consumer.slice';
import {
  fetchConsumerMember,
  fetchConsumerMembers
} from './consumerMember.slice';
import { fetchOrganizations } from './organization.slice';
import { Note } from '../interfaces';
import { RootState } from '.';
import { safeArray } from '../utils';

const noteAdapter = createEntityAdapter<Note>();
const initialState = noteAdapter.getInitialState({ isLoading: false });
const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes.entities));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchConsumers.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchConsumerMember.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchConsumerMembers.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
    builder.addCase(fetchConsumer.fulfilled, (state, { payload }) => {
      noteAdapter.upsertMany(state, safeArray(payload.notes));
    });
  }
});

export const noteSelectors = noteAdapter.getSelectors<RootState>(
  state => state.notes
);

export default notesSlice.reducer;
