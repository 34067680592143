import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    overflow: 'auto'
  }
}));

export default function ModalContent({ children }) {
  const classes = useStyles();

  return (
    <Box
      pb={1}
      className={classes.content}
      display="flex"
      flex="1"
      flexDirection="column"
    >
      {children}
    </Box>
  );
}

ModalContent.propTypes = {
  children: PropTypes.node.isRequired
};

ModalContent.defaultProps = {};
