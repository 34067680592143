import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Clickable from './Clickable';
import Tabbar from './Tabbar';
import Icon from './Icon';

const useStyles = makeStyles(theme => {
  const yPadding = theme.spacing(2);
  const xPadding = theme.spacing(3);

  const label = {
    padding: `${yPadding}px ${xPadding}px`
  };

  return {
    tab: {
      lineHeight: '1.2em',
      textAlign: 'center',
      background: theme.color.background.default,
      fontSize: '.875em',
      marginLeft: '2px',
      marginRight: '2px',
      fontWeight: '600',
      color: theme.color.text.light,
      border: `1px solid ${theme.color.background.default}`,
      borderRadius: `${theme.borderRadius.small} ${theme.borderRadius.small} 0 0`,
      transition: 'border 100ms linear, color 100ms linear',
      padding: '1em 1.5em',
      position: 'relative',
      '&:first-of-type': {
        marginLeft: '0px'
      },
      '&:last-of-type': {
        marginRight: '0px'
      },
      '&:hover': {
        color: ({ tabbarContext }) =>
          tabbarContext.disabled ? null : theme.color.text.main,
        border: ({ tabbarContext }) =>
          tabbarContext.disabled
            ? null
            : `1px solid ${theme.color.border.main}`,
        '&:after': {
          content: '""',
          position: 'absolute',
          zIndex: ({ tabbarContext }) => (tabbarContext.disabled ? null : 1),
          bottom: -2,
          width: '100%',
          background: theme.color.background.default,
          left: 0,
          height: yPadding
        }
      }
    },
    active: {
      color: theme.color.text.main,
      border: `1px solid ${theme.color.border.main}`,
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        bottom: -2,
        width: '100%',
        background: theme.color.background.default,
        left: 0,
        height: yPadding
      }
    },
    label
  };
});

export default function TabbarTab(props) {
  const tabbarContext = useContext(Tabbar.Context);
  const classes = useStyles({ props, tabbarContext });
  const [hovered, setHovered] = useState(false);
  const active = props.index === tabbarContext.index;

  function getIconColor() {
    if (active) return 'primary';

    if (hovered) return 'text';

    return 'default';
  }

  return (
    <Clickable
      disabled={tabbarContext.disabled}
      className={`${classes.tab} ${active ? classes.active : ''}`}
      onClick={() => tabbarContext.setTab(props.index)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      key={props.index}
    >
      <Box display="flex" alignItems="center">
        {props.icon && (
          <Icon name={props.icon} size="small" color={getIconColor()} circle />
        )}
        <Box ml={props.icon ? 0.5 : 0} className={classes.label}>
          {props.children}
        </Box>
      </Box>
    </Clickable>
  );
}

TabbarTab.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string,
  // children props are used in Tabbar
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.node.isRequired
};

TabbarTab.defaultProps = {
  children: undefined
};
