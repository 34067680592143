import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Skeleton, Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  stepper: {
    '& .MuiStep-root': {
      cursor: 'pointer',
      '&:hover': {
        '& .MuiStepLabel-label': {
          color: theme.color.text.dark
        },
        '& .MuiStepIcon-root': {
          color: theme.color.primary.dark
        }
      }
    },
    '& .MuiStepLabel-label': {
      color: theme.color.text.main
    },
    '& .MuiStepIcon-root': {
      color: theme.color.primary.main
    },
    '& .MuiStepIcon-root.Mui-active': {
      color: theme.color.primary.main
    },
    '& .MuiStepConnector-line': {
      borderColor: theme.color.border.main
    }
  }
}));

/* This component creates a scrollable directory based on h2 headings */
export default function SmartDirectory(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const [chapters, setChapters] = useState([]);
  const chaptersRef = useRef(chapters);
  chaptersRef.current = chapters;

  const refreshInterval = useRef();
  const renderTimeout = useRef();
  const [asyncRendered, setAsyncRendered] = useState(false);

  function refreshChapters() {
    const targetContainer = document.getElementById(props.container);

    if (targetContainer) {
      const updatedChapters = targetContainer.querySelectorAll(
        props.chapterIdentifier
      );

      const tempChapters = [];
      for (const chapter of updatedChapters) {
        tempChapters.push({
          title: chapter.getAttribute('data-label'),
          // description: chapter.getAttribute('data-description'),
          icon: chapter.getAttribute('data-icon'),
          htmlElement: chapter
        });
      }

      if (tempChapters.length != chaptersRef.current.length) {
        clearTimeout(renderTimeout.current);
        renderTimeout.current = setTimeout(() => {
          setAsyncRendered(true);
        }, 200);
      }

      setChapters(tempChapters);
    } else {
      console.error(
        'SmartDirectory:',
        `Given props.container '${props.container}' does not exist in DOM.`
      );
    }
  }

  useEffect(() => {
    refreshChapters();

    // Make sure Directory changes dynamically
    // Maybe there are better solutions
    refreshInterval.current = setInterval(() => {
      refreshChapters();
    }, 100);

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, []);

  return chapters.length && asyncRendered ? (
    <Stepper
      className={classes.stepper}
      nonLinear
      activeStep={chapters.length - 1}
      orientation="vertical"
    >
      {chapters.map(chapter => (
        <Step
          key={chapter.title}
          onClick={() => {
            window?.scrollTo({
              top:
                chapter.htmlElement?.offsetTop -
                (theme.contentOffset ? parseInt(theme.contentOffset, 10) : 0),
              left: 0,
              behavior: 'smooth'
            });
          }}
        >
          <StepLabel
            icon={
              chapter.icon ? (
                <Icon size="large" color="primary" name={chapter.icon} />
              ) : null
            }
          >
            {chapter.title}
          </StepLabel>
          {/* <StepContent>
        <Typography>{step.description}</Typography>
      </StepContent> */}
        </Step>
      ))}
    </Stepper>
  ) : (
    <Box display="flex" flexDirection="column" gap={5}>
      <Box display="flex" gap={1}>
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
      </Box>
      <Box display="flex" gap={1}>
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
      </Box>
      <Box display="flex" gap={1}>
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
      </Box>
      <Box display="flex" gap={1}>
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
      </Box>
      <Box display="flex" gap={1}>
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
      </Box>
    </Box>
  );
}

SmartDirectory.propTypes = {
  container: PropTypes.string.isRequired,
  chapterIdentifier: PropTypes.string,
  'data-testid': PropTypes.string
};

SmartDirectory.defaultProps = {
  'data-testid': undefined,
  chapterIdentifier: 'h2'
};
