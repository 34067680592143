import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import Hoverbox from './Hoverbox';
import Form from './Form';
import Spacing from './Spacing';

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: '400',
    lineHeight: '2.2rem',
    color: theme.color.text.main
  },
  count: {
    height: '25px',
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'center',
    color: theme.color.text.main,
    fontSize: theme.font?.size?.input,
    background: theme.color.background.grey,
    borderRadius: '4px'
  }
}));

function getActiveFilterCount(values, exceptions) {
  let i = 0;
  for (let field in values) {
    if (
      !(
        values[field] === null ||
        values[field] === undefined ||
        values[field] === '' ||
        values[field]?.length === 0 ||
        values[field] === 'all' ||
        exceptions.includes(field)
      )
    ) {
      i += 1;
    }
  }

  return i;
}

export default function FilterForm(props) {
  const classes = useStyles(props);

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      initialValuesEqual={(v1, v2) => JSON.stringify(v1) === JSON.stringify(v2)}
      render={({ handleSubmit }) => (
        <Hoverbox
          id={`form-filter-group-${props.title}`}
          mode="click"
          disabled={props.disabled}
          keepMounted
          target={
            <>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  const activeFilterCount = getActiveFilterCount(
                    values,
                    props.filterCountException
                  );

                  return (
                    <Button
                      className={classes.button}
                      variant="outlined"
                      disabled={props.disabled}
                      color={activeFilterCount > 0 ? 'primary' : 'default'}
                      endIcon="arrow-down"
                      data-testid={
                        props['data-testid']
                          ? `${props['data-testid']}-btn`
                          : undefined
                      }
                    >
                      {props.title}
                      {activeFilterCount > 0 ? (
                        <Box
                          className={classes.count}
                          ml={0.5}
                          mr={0.25}
                          px={1}
                        >
                          {activeFilterCount}
                        </Box>
                      ) : null}
                    </Button>
                  );
                }}
              </FormSpy>
            </>
          }
          position="bottom"
          width="20em"
        >
          <Box className={classes.subitems}>
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true, dirty: true }}
                onChange={({ dirty, values }) => {
                  if (dirty) {
                    handleSubmit(values);
                  }
                }}
              />
              {props.children}
              <Spacing y={2} />
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                disabled={props.disabled}
                onClick={props.onDiscard}
              >
                {/* TBD: Intl */}
                Zurücksetzen
              </Button>
            </form>
          </Box>
        </Hoverbox>
      )}
    />
  );
}

FilterForm.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  filterCountException: PropTypes.array,
  onDiscard: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired
};

FilterForm.defaultProps = {
  'data-testid': undefined,
  filterCountException: [],
  disabled: false
};
