import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import DrawerItem from './DrawerItem';
import DrawerSubItem from './DrawerSubItem';

const useStyles = makeStyles(theme => ({
  drawer: {
    minHeight: '100%',
    overflow: 'hidden',
    background: theme.color.background.default,
    borderRight: `1px solid ${theme.color.border.main}`,
    '& a': {
      textDecoration: 'none',
      color: theme.color.common.grey.dark,
      fontWeight: 600,
      fontSize: '1em',
      cursor: 'pointer'
    }
  }
}));

const DrawerContext = React.createContext({});

// eslint-disable-next-line react/display-name
const Drawer = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const [visible, setVisible] = useState(props.open);
  const [index, setIndex] = useState();

  function updateIndex(i) {
    setIndex(i);
    props.onChange(i);
  }

  function onOpenToggle() {
    setVisible(!visible);
    props.onOpenToggle();
  }

  useEffect(() => {
    if (props.open !== visible) {
      setVisible(props.open);
    }
  }, [props.open]);

  return (
    <DrawerContext.Provider
      value={{
        visible: visible,
        onClick: updateIndex,
        currentIndex: index,
        onOpenToggle: onOpenToggle
      }}
    >
      <Box
        className={classes.drawer}
        display="inline-flex"
        flexDirection="column"
        justifyContent="space-between"
        ref={ref}
      >
        <Box>{props.topItems}</Box>
        <Box>
          {props.bottomItems}
          <Drawer.Item
            icon={visible ? 'arrow-left' : 'arrow-right'}
            bottom
            index="openclose"
            visible={visible}
            onClick={onOpenToggle}
            active={false}
            // TODO: Intl
            aria-label={
              visible ? 'Seitenmenü verkleinern' : 'Seitenmenü vergrößern'
            }
          />
        </Box>
      </Box>
    </DrawerContext.Provider>
  );
});

Drawer.propTypes = {
  topItems: PropTypes.node,
  bottomItems: PropTypes.node,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  onOpenToggle: PropTypes.func
};

Drawer.defaultProps = {
  onChange: () => {},
  open: false,
  onOpenToggle: () => {},
  topItems: null,
  bottomItems: null
};

Drawer.Item = DrawerItem;
Drawer.SubItem = DrawerSubItem;
Drawer.Context = DrawerContext;

export default Drawer;
