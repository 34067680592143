// general
export { Box, Grid, Skeleton } from '@mui/material';
export {
  StylesProvider,
  ServerStyleSheets,
  makeStyles,
  withStyles,
  useTheme
} from '@mui/styles';

// emotion
import { CacheProvider } from '@emotion/react';
import createEmotionServer from '@emotion/server/create-instance';
import createEmotionCache from '@emotion/cache';
export const Emotion = {
  CacheProvider,
  createServer: createEmotionServer,
  // https://github.com/mui/material-ui/blob/master/examples/nextjs/src/createEmotionCache.js
  createCache: () => {
    return createEmotionCache({ key: 'css', prepend: true });
  }
};

// form
export {
  Field,
  FormSpy,
  useField,
  useForm,
  useFormState
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
export { arrayMutators };
export { FORM_ERROR } from 'final-form';
export { FormHelperText } from '@mui/material';
export { FormControl } from '@mui/material';

// interfaces
export type { Theme as DefaultTheme } from '@mui/material/styles';

// components
export {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './components/Accordion';
export { default as Avatar } from './components/Avatar';
export { default as Badge } from './components/Badge';
export { default as Breadcrumb } from './components/Breadcrumb';
export { default as Button } from './components/Button';
export { default as Calendar } from './components/Calendar';
export { default as Checkbox } from './components/Checkbox';
export { default as Chip } from './components/Chip';
export { default as Clickable } from './components/Clickable';
export { default as ColorLine } from './components/ColorLine';
export { default as Content } from './components/Content';
export { default as CurrencyTextField } from './components/CurrencyTextField';
export { default as Dashboard } from './components/Dashboard';
export { default as DataTile } from './components/DataTile';
export { default as DatePicker } from './components/DatePicker';
export { default as Drawer } from './components/Drawer';
export { default as Dropdown } from './components/Dropdown';
export { default as DurationPicker, formatDuration, timeToDuration } from './components/DurationPicker';
export { default as ElementPicker } from './components/ElementPicker';
export { default as Error } from './components/Error';
export { default as FilterForm } from './components/FilterForm';
export { default as Footer } from './components/Footer';
export { default as Form } from './components/Form';
export { default as FormFieldArray } from './components/FormFieldArray';
export { default as FormPresenterMultiLine } from './components/FormPresenterMultiLine';
export { default as FormPresenterPartialContent } from './components/FormPresenterPartialContent';
export { default as FormPresenterSingleLine } from './components/FormPresenterSingleLine';
export { default as FormPresenterTitle } from './components/FormPresenterTitle';
export { default as FormatBoolean } from './components/FormatBoolean';
export { default as FormatLatLng } from './components/FormatLatLng';
export { default as FormatNumber } from './components/FormatNumber';
export { default as Heading } from './components/Heading';
export { default as Hoverbox } from './components/Hoverbox';
export { default as Hr } from './components/Hr';
export { default as Icon } from './components/Icon';
export { default as InformationGrid } from './components/InformationGrid';
export { default as JsonFields } from './components/JsonFields/JsonFields';
export { default as Label } from './components/Label';
export { default as LoadingIndicator } from './components/LoadingIndicator';
export { default as Message } from './components/Message';
export { default as Modal } from './components/Modal';
export { default as MoneyInput } from './components/MoneyInput';
export { default as MultiCreate } from './components/MultiCreate';
export { default as MultiSelect } from './components/MultiSelect';
export { default as MultiToggle } from './components/MultiToggle';
export { default as Note } from './components/Note';
export { default as NumberField } from './components/NumberField';
export { default as Pagination } from './components/Pagination';
export { default as Paper } from './components/Paper';
export { default as PartialContent } from './components/PartialContent';
export { default as PrintButton } from './components/PrintButton';
export { default as Select } from './components/Select';
export { default as SmartDirectory } from './components/SmartDirectory';
export { default as Spacing } from './components/Spacing';
export { default as SplitButton } from './components/SplitButton';
export { default as StatusIndicator } from './components/StatusIndicator';
export { default as Sticky } from './components/Sticky';
export { default as Tabbar } from './components/Tabbar';
export { default as Table } from './components/Table';
export { default as TablePagination } from './components/TablePagination';
export { default as Text } from './components/Text';
export { default as TextField } from './components/TextField';
export { default as TextShortener } from './components/TextShortener';
export { default as ThemeProvider } from './components/ThemeProvider';
export { default as TimePicker } from './components/TimePicker';
export { default as TimeRangePicker } from './components/TimeRangePicker';
export { default as TimeTable } from './components/TimeTable';
export { default as Topbar } from './components/Topbar';
export { default as Typeahead } from './components/Typeahead';
export { default as Void } from './components/Void';
export { default as WeekPicker } from './components/WeekPicker';

// utils
export * from './utils';
