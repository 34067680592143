import PropTypes from 'prop-types';
import React from 'react';
import { FormHelperText } from '@mui/material';

export default function Error(props) {
  return (
    <FormHelperText style={{ margin: 0 }} error>
      {props.children}
    </FormHelperText>
  );
}

Error.propTypes = {
  children: PropTypes.node
};

Error.defaultProps = {
  children: undefined
};
