import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Clickable from './Clickable';
import Icon from './Icon';
import classNames from '../utils/react/classNames';

const useStyles = makeStyles(theme => ({
  checkbox: {
    '& input[type="checkbox"]': {
      display: 'none'
    },
    ...(theme.components?.checkbox?.root
      ? theme.components?.checkbox?.root
      : {})
  },
  label: {
    fontWeight: 500,
    color: props =>
      props.disabled ? theme.color.text.light : theme.color.text.main,
    fontSize: theme.font?.size?.input,
    ...(theme.components?.checkbox?.label
      ? theme.components?.checkbox?.label
      : {})
  },
  box: {
    width: '1em',
    height: '1em',
    border: props => [
      [
        1,
        'solid',
        props.disabled
          ? theme.color.common.grey.light
          : theme.color.common.grey.main
      ]
    ],
    ...(theme.components?.checkbox?.box ? theme.components?.checkbox?.box : {})
  },
  checked: {
    background: props => {
      if (props.color === 'primary') {
        return props.disabled
          ? theme.color.primary.light
          : theme.color.primary.main;
      }

      return 'transparent';
    }
  }
}));

export default function Checkbox(props) {
  const [checked, setChecked] = useState(props.checked);
  const classes = useStyles(props);

  function handleCheckbox(e) {
    setChecked(!checked);
    setTimeout(() => props.onChange(!checked, e));
  }

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const inner = (
    <Box display="flex" alignItems="center">
      <input
        type="checkbox"
        tabIndex={props.readOnly ? -1 : 0}
        readOnly={props.readOnly}
        checked={checked}
        disabled={props.disabled}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classNames([
          classes.box,
          props.checked ? classes.checked : null
        ])}
      >
        {checked && (
          <Icon
            name="check"
            disabled={props.disabled}
            color={props.color === 'primary' ? 'contrastPrimary' : props.color}
          />
        )}
      </Box>
      <Box pl={1} className={classes.label}>
        {props.children}
      </Box>
    </Box>
  );

  return (
    <Box className={classes.checkbox} display="inline-flex">
      {props.readOnly ? (
        inner
      ) : (
        <Clickable disabled={props.disabled} onClick={e => handleCheckbox(e)}>
          {inner}
        </Clickable>
      )}
    </Box>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'primary', 'secondary'])
};

Checkbox.defaultProps = {
  children: '',
  checked: false,
  readOnly: false,
  onChange: () => {},
  disabled: false,
  color: 'default'
};
