import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { occasionsEntity } from '../schemas';
import { fetchBooking, fetchBookings } from './booking.slice';
import { Occasion, Pagination } from '../interfaces';
import { RootState } from '.';
import { fetchRequest, fetchRequests } from './request.slice';
import { fetchConsumerTeams } from './consumerTeam.slice';
import { paginate, safeArray } from '../utils';

export const fetchOccasions = createAsyncThunk(
  'occasions/all',
  async (filter: Pagination) => {
    const response = await client.get('/occasions', filter);

    const normalized = normalize(response.data.data, [occasionsEntity]);
    const paginated = paginate(normalized, response);
    return paginated.entities;
  }
);

const occasionAdapter = createEntityAdapter<Occasion>();
const initialState = occasionAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const occasionSlice = createSlice({
  name: 'occasions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions.entities));
      state.pagination = payload.occasions.pagination;
    });
    builder.addCase(fetchOccasions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchOccasions.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchOccasions.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      occasionAdapter.upsertMany(state, safeArray(payload.occasions));
    });
  }
});

export const occasionSelectors = occasionAdapter.getSelectors<RootState>(
  state => state.occasions
);

export default occasionSlice.reducer;
