import axios from 'axios';
import getConfig from 'next/config';

import isClient from 'shared/Redux/utils/isClient';
import logoutSideEffects, {
  logoutRedirectUrl
} from 'shared/Redux/utils/logout';
import { redirect } from 'shared/App/utils/Globals';

import { getClientSessionToken } from './session';

const env = getConfig()?.publicRuntimeConfig;

// eslint-disable-next-line import/prefer-default-export
export async function client(
  endpoint,
  { body, method, params, data, responseType } = {}
) {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  // This is currently required because there are no translations for occasions
  axios.defaults.headers.common['Accept-Language'] = 'de';
  axios.defaults.baseURL = env.apiHost;

  // SSR Cookie handling takes place at store initialization in store.ts
  // because there is the request.cookies available
  const sessionToken = getClientSessionToken();

  if (isClient) {
    if (sessionToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${sessionToken}`;
    } else {
      axios.defaults.headers.common.Authorization = null;
    }
  }

  const res = axios(endpoint, {
    method,
    params,
    body,
    data,
    responseType
  });
  return res.catch(e => {
    // Handle failed requests
    switch (e.response.status) {
      case 401:
        // We assume that we're dealing with an expired or invalid
        // session_token, so we're clearing the session before
        // redirecting the user to /login.
        //
        // TODO: Not all 401 responses mean that the session_token
        //       isn't valid anymore. We should probably show a
        //       dynamic error page for those cases.
        // Do not redirect on token validation
        // pages/ user_confirm handles that response
        if (e?.config?.url?.startsWith('/confirms')) {
          break;
        }

        const currentUrl = document.location.href.replace(
          `${document.location.protocol}//${document.location.hostname}`,
          ''
        );

        logoutSideEffects();

        const redirectUrl = logoutRedirectUrl(currentUrl);
        if (redirectUrl) redirect(redirectUrl, res);

        break;

      default:
    }

    // TBD: This is a workaround since redux-toolkit serializes errors
    // https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
    throw new Error(
      JSON.stringify({
        code: e.code,
        message: e.message,
        status: e.response?.status,
        statusText: e.response?.statusTest,
        data: e.response?.data
      })
    );
  });
}

client.get = (endpoint, params, responseType) =>
  client(endpoint, { method: 'GET', params, responseType });

client.post = (endpoint, data, params) =>
  client(endpoint, { method: 'POST', data, params });

client.put = (endpoint, data) => client(endpoint, { method: 'PUT', data });

client.delete = endpoint => client(endpoint, { method: 'DELETE' });
