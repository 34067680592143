import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { eventsEntity } from '../schemas';
import { RootState } from '.';
import { CustomEvent, UUID } from '../interfaces';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { safeArray } from '../utils';

export const fetchEvents = createAsyncThunk('events/all', async () => {
  const response = await client.get('/events');
  const normalized = normalize(response.data.data, [eventsEntity]);

  return normalized.entities;
});

export const fetchEvent = createAsyncThunk(
  'events/single',
  async (id: string) => {
    const response = await client.get(`/events/${id}`);
    const normalized = normalize(response.data.data, eventsEntity);

    return normalized.entities;
  }
);

export const createEvent = createAsyncThunk(
  'events/create',
  async (event: CustomEvent) => {
    const response = await client.post('/events', {
      event
    });
    const normalized = normalize(response.data.data, eventsEntity);

    return normalized.entities;
  }
);

export const deleteEvent = createAsyncThunk(
  'events/delete',
  async (event: { id: UUID }) => {
    await client.delete(`/events/${event.id}`);
    const normalized = normalize(event, eventsEntity);

    return normalized.entities;
  }
);

export const updateEvent = createAsyncThunk(
  'events/update',
  async ({ id, event }: { id: string; event: Partial<CustomEvent> }) => {
    const response = await client.put(`/events/${id}`, {
      event
    });
    const normalized = normalize(response.data.data, eventsEntity);

    return normalized.entities;
  }
);

const eventAdapter = createEntityAdapter<CustomEvent>();
const initialState = eventAdapter.getInitialState({ isLoading: false });
const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      eventAdapter.upsertMany(state, safeArray(payload.events.entities));
    });
    builder.addCase(fetchEvents.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
      state.isLoading = false;
    });
    builder.addCase(fetchEvents.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchEvent.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
      state.isLoading = false;
    });
    builder.addCase(fetchEvent.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createEvent.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
      state.isLoading = false;
    });
    builder.addCase(createEvent.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
      state.isLoading = false;
    });
    builder.addCase(updateEvent.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteEvent.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
      state.isLoading = false;
    });
    builder.addCase(deleteEvent.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      eventAdapter.upsertMany(state, safeArray(payload.events));
    });
  }
});

export const eventSelectors = eventAdapter.getSelectors<RootState>(
  state => state.events
);

export default eventSlice.reducer;
