import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

import Drawer from './Drawer';

const useStyles = makeStyles(theme => ({
  drawerItemLogo: {
    height: '.9em',
    width: '.9em'
  },
  drawerItem: {
    transition: 'background-color 100ms linear, width 300ms linear',
    cursor: ({ props }) => (props.disabled ? 'default' : 'pointer'),
    backgroundColor: ({ props, state }) =>
      state.isHovered || state.active
        ? theme.color.background.grey
        : theme.color.common.white,
    '&:focus': {
      backgroundColor: ({ props }) =>
        props.index === 'openclose' || props.disabled
          ? theme.color.common.white
          : theme.color.background.grey,

      outline: 'none',
      pointerEvents: ({ props }) => (props.disabled ? 'none' : 'auto')
    },
    '&:hover': {
      backgroundColor: theme.color.background.grey,
      cursor: ({ props }) => (props.disabled ? 'default' : 'pointer')
    }
  },
  drawerItemText: {
    lineHeight: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.color.text.main,
    fontWeight: 600,
    fontSize: '.8em'
  }
}));

export default function DrawerSubItem(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [active, setActive] = useState(props.active);
  const classes = useStyles({ props, state: { isHovered, active } });
  const handleOnKeyDown = e => {
    if (e.key === 'Enter') {
      onClick(props.index);
    }
  };

  useEffect(() => {
    if (props.active !== active) {
      setActive(props.active);
    }
  }, [props.active]);

  useEffect(() => {
    setActive(props.index === drawerContext.currentIndex || props.active);
  });

  const drawerContext = useContext(Drawer.Context);
  const onClick = drawerContext.onClick;
  const visible = drawerContext.visible;

  function hoverMenuEntryClickHandler(index) {
    onClick(props.index);
  }

  return (
    <>
      <Box
        component={props.component}
        display="flex"
        className={classes.drawerItem}
        p={1.7}
        alignItems="center"
        // onClick={() => {
        //   if (!props.disabled) {
        //     if (props.index === 'openclose') {
        //       props.onClick();
        //     } else if (visible) {
        //       hoverMenuEntryClickHandler(props.index);
        //     } else {
        //       onClick(props.index);
        //     }
        //   }
        // }}
        // onMouseEnter={() => !props.disabled && setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        tabIndex="0"
        // onKeyDown={handleOnKeyDown}
        href={props.href}
        aria-label={props['aria-label']}
      >
        {visible && <Box display="flex" className={classes.drawerItemLogo} />}
        <Box className={classes.drawerItemText} pl={1.5}>
          {props.title}
        </Box>
      </Box>
    </>
  );
}

DrawerSubItem.defaultProps = {
  active: false,
  disabled: false
};

DrawerSubItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired
};
