import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';

export default function ModalHeading({ children }) {
  return (
    <Box pt={1} pb={2}>
      {children}
    </Box>
  );
}

ModalHeading.propTypes = {
  children: PropTypes.node.isRequired
};

ModalHeading.defaultProps = {};
