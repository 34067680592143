import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import convertToDateString from '../utils/date/convertToString';
import convertToTimeString from '../utils/time/convertToString';

import Icon from './Icon';
import Void from './Void';
import Spacing from './Spacing';

const useStyles = makeStyles((theme, props) => ({
  AwardCriteriaDownload: {
    marginBottom: '4rem',
    textAlign: 'center'
  },
  Dashboard: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  Headline: {
    fontSize: theme.font.size.large,
    lineHeight: '3rem',
    marginTop: '3rem',
    textAlign: 'center'
  },
  InfoText: {
    fontSize: theme.font.size.text,
    lineHeight: '1.2rem',
    margin: '2rem auto 4rem',
    maxWidth: '45rem',
    width: '75%'
  },
  TileWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: '55rem',
    gap: theme.spacing(3)
  },
  Time: {
    display: 'flex',
    fontSize: theme.font.size.text,
    alignSelf: 'flex-start'
  },
  version: {
    fontWeight: 'lighter',
    fontSize: theme.font.size.small
  },
  tile: {
    width: '9rem',
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    textDecoration: 'none !important',
    color: theme.color.text.main
  },
  tileIconWrapper: {
    height: '9rem',
    width: '9rem',
    boxSizing: 'border-box',
    background: theme.color.background.grey,
    borderRadius: theme.borderRadius.small,
    border: `2px solid ${theme.color.background.grey}`,
    '&:hover': {
      border: `2px solid ${theme.color.primary.main}`
    },
    '& svg': {
      margin: '0 auto',
      height: '50%',
      width: '50%'
    },
    '& > div': {
      width: '100%'
    }
  }
}));

export default function Dashboard(props) {
  const classes = useStyles(props);

  const tiles =
    props.tiles.length > 0
      ? props.tiles.map((tile, index) => (
          <props.tileLinkComponent
            href={tile.href}
            disabled={tile.disabled}
            key={`dashboard-clicktile-${index}`}
            className={classes.tile}
          >
            <Box
              className={classes.tileIconWrapper}
              display="flex"
              alignItems="center"
              mb={1}
            >
              <Icon
                aria-hidden={true}
                name={tile.icon}
                color={tile.disabled ? 'default' : tile.color || 'primary'}
              />
            </Box>
            {tile.text}
          </props.tileLinkComponent>
        ))
      : [];

  return (
    <div className={classes.Dashboard} data-testid="dashboard">
      <div className={classes.Time} data-testid="dashboard-date-time">
        <b>
          {convertToDateString(new Date(), {
            lang: props.lang,
            withTime: false
          })}
        </b>
        <Box px=".25rem" />
        {convertToTimeString(new Date(), props.lang).pretty}
      </div>
      <div className={classes.Headline} data-testid="dashboard-welcome-text">
        {props.headline}
      </div>
      {!props.infoText && !props.awardCriteriaDownload ? (
        <Spacing y={3} />
      ) : (
        <Void />
      )}
      {props.infoText ? (
        <div className={classes.InfoText} data-testid="dashboard-info-text">
          <p>{props.infoText}</p>
        </div>
      ) : (
        <Void />
      )}
      {props.awardCriteriaDownload ? (
        <div
          className={classes.AwardCriteriaDownload}
          data-testid="dashboard-award-criteria-download"
        >
          {props.awardCriteriaDownload}
        </div>
      ) : (
        <Void />
      )}
      <div className={classes.TileWrapper} data-testid="dashboard-click-tiles">
        {tiles}
      </div>
      {props.version ? (
        <>
          <Spacing y={6} />
          <div className={classes.version}>
            {props.version}
            {props.commit ? (
              <>
                {' - '}(
                <a
                  href={props.commitLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.commit}
                </a>
                )
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}

Dashboard.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  commit: PropTypes.string,
  commitLink: PropTypes.string,
  version: PropTypes.string,
  lang: PropTypes.string,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string
    })
  ),
  routePushFn: PropTypes.func.isRequired
};

Dashboard.defaultProps = {
  headline: '',
  commit: null,
  commitLink: null,
  tileLinkComponent: 'a',
  version: null,
  lang: null,
  tiles: []
};
