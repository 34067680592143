import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { unitsEntity } from '../schemas';
import { Pagination, Unit } from '../interfaces';
import { RootState } from '.';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchDomain, fetchDomains } from './domain.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { paginate, safeArray } from '../utils';
import { fetchGroup, fetchGroups, updateGroup } from './group.slice';

export const createUnit = createAsyncThunk('units/create', async unit => {
  const response = await client.post('/units', {
    unit
  });
  const normalized = normalize(response.data.data, unitsEntity);

  return normalized.entities;
});

export const fetchUnit = createAsyncThunk(
  'units/single',
  async (id: string) => {
    const response = await client.get(`/units/${id}`);
    const normalized = normalize(response.data.data, unitsEntity);

    return normalized.entities;
  }
);

export const fetchUnits = createAsyncThunk(
  'units/all',
  async (filter: Pagination) => {
    const response = await client.get(`/units`, filter);
    const normalized = normalize(response.data.data, [unitsEntity]);

    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const updateUnit = createAsyncThunk(
  'units/update',
  async ({ id, unit }: { id: string; unit: Unit }) => {
    const response = await client.put(`/units/${id}`, {
      unit
    });
    const normalized = normalize(response.data.data, unitsEntity);

    return normalized.entities;
  }
);

export const updateUnitOrder = createAsyncThunk(
  'units/update_order',
  async ({ id, unit }: { id: string; unit: Unit }) => {
    const response = await client.put(`/units/update_order/${id}`, {
      unit
    });
    const normalized = normalize(response.data.data, unitsEntity);

    return normalized.entities;
  }
);

export const archiveUnit = createAsyncThunk(
  'units/archive',
  async (id: number) => {
    await client.put(`/archive/units/${id}`);
    return id;
  }
);

export const unarchiveUnit = createAsyncThunk(
  'units/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/units/${id}`);
    return id;
  }
);

export const deleteUnit = createAsyncThunk(
  'units/delete',
  async (id: number) => {
    await client.delete(`/units/${id}`);
    return id;
  }
);

export const fetchUnitHasDates = createAsyncThunk(
  'units/has_dates',
  async (id: string) => {
    const response = await client.get(`/units/has_dates/${id}`);
    return response.data;
  }
);

const unitAdapter = createEntityAdapter<Unit>();
const initialState = unitAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const unitSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      unitAdapter.upsertMany(state, safeArray(payload.units.entities));
      state.pagination = payload.units.pagination;
    });
    builder.addCase(createUnit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createUnit.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
      state.isLoading = false;
    });
    builder.addCase(createUnit.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchUnits.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnits.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchUnits.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchUnit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnit.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
      state.isLoading = false;
    });
    builder.addCase(fetchUnit.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateUnit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateUnit.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
      state.isLoading = false;
    });
    builder.addCase(updateUnit.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateUnitOrder.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateUnitOrder.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
      state.isLoading = false;
    });
    builder.addCase(updateUnitOrder.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteUnit.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteUnit.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteUnit.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchGroup.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchDomain.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(fetchDomains.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      unitAdapter.upsertMany(state, safeArray(payload.units));
    });
  }
});

export const unitSelectors = unitAdapter.getSelectors<RootState>(
  state => state.units
);

export default unitSlice.reducer;
