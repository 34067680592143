import { AnyAction } from 'redux';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchConsumerTeams } from './consumerTeam.slice';
import { OccasionSecondaryCategory } from '../interfaces';
import { RootState } from '.';
import { fetchRequest } from './request.slice';
import { fetchOccasions } from './occasion.slice';
import { safeArray } from '../utils';

const occasionSecondaryCategoryAdapter =
  createEntityAdapter<OccasionSecondaryCategory>();
const initialState = occasionSecondaryCategoryAdapter.getInitialState({
  isLoading: false
});
const occasionSecondaryCategoriesSlice = createSlice({
  name: 'occasionSecondaryCategories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories.entities)
      );
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories)
      );
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories)
      );
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories)
      );
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories)
      );
    });
    builder.addCase(fetchOccasions.fulfilled, (state, { payload }) => {
      occasionSecondaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionSecondaryCategories)
      );
    });
  }
});

export const occasionSecondaryCategorySelectors =
  occasionSecondaryCategoryAdapter.getSelectors<RootState>(
    state => state.occasionSecondaryCategories
  );

export default occasionSecondaryCategoriesSlice.reducer;
