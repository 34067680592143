import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';

import { RootState } from '.';
import { Statistic } from '../interfaces';
import { client } from '../api/client';
import { safeArray } from '../utils';

export const downloadStatistics = createAsyncThunk(
  'statistics/download',
  async (filter: { statistic_name: string }, thunkApi) => {
    try {
      return new Promise(resolve => {
        client
          .get('/statistic', filter, 'blob')
          .then(response => {
            const filename = response.headers['content-disposition']
              .split(';')
              .filter(text => text.includes('filename='))[0]
              .split('filename="')[1]
              .split('"')[0];

            return {
              data: response.data,
              filename
            };
          })
          .then(async response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', response.filename);
            document.body.appendChild(link);
            link.click();

            resolve(null);
          });
      });
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const fetchStatistics = createAsyncThunk(
  'statistics/single',
  async (filter: { statistic_name: string }) => {
    const response = await client.get('/statistic', filter);

    return response?.data;
  }
);

const statisticAdapter = createEntityAdapter<Statistic>();
const initialState = statisticAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const statisticSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      statisticAdapter.upsertMany(
        state,
        safeArray(payload.statistics.entities)
      );
      state.isLoading = false;
    });
    builder.addCase(fetchStatistics.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchStatistics.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const statisticSelectors = statisticAdapter.getSelectors<RootState>(
  state => state.statistics
);

export default statisticSlice.reducer;
