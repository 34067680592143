import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import Heading from './Heading';
import Spacing from './Spacing';
import Sticky from './Sticky';
import Text from './Text';
import SmartDirectory from './SmartDirectory';

const useStyles = makeStyles(theme => ({
  directory: {
    width: '100%',
    boxSizing: 'border-box',
    background: theme.color.background.grey
  }
}));

export default function FormDirectory(props) {
  const classes = useStyles(props);

  return (
    <Box flexShrink={0} flexBasis="33%" boxSizing="border-box">
      <Sticky>
        {props.title ? (
          <Heading context type="h1">
            {props.title}
          </Heading>
        ) : null}
        {props.description ? (
          <>
            <Text color="light">{props.description}</Text>
          </>
        ) : null}
        {props.title || props.description ? <Spacing y={2} /> : null}
        <Box className={classes.directory} p={2}>
          <SmartDirectory container={props.formId} />
        </Box>
      </Sticky>
    </Box>
  );
}

FormDirectory.propTypes = {
  directory: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  formId: PropTypes.string.isRequired,
  'data-testid': PropTypes.string
};

FormDirectory.defaultProps = {
  directory: [],
  title: undefined,
  description: undefined,
  'data-testid': undefined
};
