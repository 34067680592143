import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { TextField as MuiTextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  compose,
  isEmail,
  isPhoneNumber,
  maxLength,
  required
} from '../utils/form/validators';
import Label from './Label';

const useStyles = makeStyles(theme => ({
  muiTextField: {
    flexGrow: props => (props.remainWidth ? 1 : 0),
    '& input, & textarea': {
      padding: '.625em .75em',
      fontSize: theme.font?.size?.input
    },
    '& .MuiInputBase-multiline': {
      padding: 0
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.error.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.main
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.primary.main
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.light
    },
    '& .Mui-disabled.MuiOutlinedInput-input': {
      textFillColor: theme.color.text.light
    },
    '& .MuiFormHelperText-root': {
      margin: 0
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  }
}));

const TextField = React.forwardRef(function TextField(props, forwardedRef) {
  const classes = useStyles(props);
  const field = useField(props.id);
  const delayTimeout = useRef();
  const [value, setValue] = useState(field.input?.value || props.value);
  const localRef = useRef();

  // This a special use cases for autofill password managers
  // since some browsers do not trigger input change event
  useEffect(() => {
    const prefilledValue = localRef.current?.value;

    if (prefilledValue !== '' && prefilledValue !== value) {
      handleChange(prefilledValue);
    }
  }, []);

  useEffect(() => {
    if (field.input?.value !== value) {
      setValue(field.input?.value);
    }
  }, [field.input?.value]);

  function change(v, e) {
    if (props.onChange) {
      props.onChange(v, e);
    } else {
      if (v || (typeof v !== 'object' && typeof v !== 'string')) {
        field.input.onChange(v);
      } else {
        field.input.onChange(null);
      }
    }
  }

  function handleChange(v, e) {
    setValue(v);

    if (props.asSearchInput) {
      clearTimeout(delayTimeout.current);

      delayTimeout.current = setTimeout(() => {
        change(v, e);
      }, 500);
    } else {
      change(v, e);
    }
  }

  function handleBlur() {
    if (props.onBlur) {
      props.onBlur();
    } else {
      field.input.onBlur();
    }
  }

  function handleFocus() {
    if (props.onFocus) {
      props.onFocus();
    } else {
      field.input.onFocus();
    }
  }

  function getValidators() {
    let validators = [];

    if (props.required) validators.push(required);
    if (props.isEmail) validators.push(isEmail);
    if (props.isPhoneNumber) validators.push(isPhoneNumber);
    if (props.maxLength) validators.push(maxLength(props.maxLength));

    if (!props.maxLength && !props.multiline) {
      validators.push(maxLength(80));
    }

    if (!props.maxLength && props.multiline) {
      validators.push(maxLength(1000));
    }

    if (props.validators?.length) validators.push(...props.validators);

    return validators;
  }

  return (
    <Field
      name={props.id}
      initialValue={props.defaultValue}
      validate={compose(getValidators())}
    >
      {({ meta, input }) => {
        const error = (meta.error || meta.submitError) && meta.touched;
        const errorText = meta.touched ? meta.error || meta.submitError : '';

        return (
          <Box flexGrow={1}>
            <Label
              for={props.id}
              disabled={
                props.asSearchInput
                  ? props.disabled && !meta.active
                  : props.disabled
              }
              required={props.required && !props.requiredWithoutAsterisk}
              popover={{
                title: props.popoverTitle,
                text: props.popoverText
              }}
            >
              {props.label}
            </Label>
            <MuiTextField
              className={classes.muiTextField}
              error={props.error || error}
              helperText={props.errorText || errorText || props.helperText}
              inputProps={{
                type: props.type,
                'aria-labelledby': props['aria-labelledby'],
                autoComplete: props.autoComplete
              }}
              InputProps={{
                notched: false
              }}
              InputLabelProps={{
                shrink: true,
                variant: 'standard',
                disableAnimation: true,
                required:
                  props.required &&
                  !props.popoverText &&
                  !props.requiredWithoutAsterisk
              }}
              inputRef={instance => {
                // Merge local and forwarded ref
                localRef.current = instance;

                if (typeof forwardedRef === 'function') {
                  forwardedRef(instance);
                } else if (typeof forwardedRef?.current === 'object') {
                  forwardedRef.current = instance;
                }
              }}
              id={props.id}
              autoFocus={props.autoFocus}
              disabled={
                props.asSearchInput
                  ? props.disabled && !meta.active
                  : props.disabled
              }
              multiline={props.multiline}
              variant="outlined"
              onChange={e => handleChange(e.target.value, e)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={props.placeholder}
              minRows={props.rows}
              maxRows={props.rowsMax}
              fullWidth={props.fullWidth}
              value={value}
              type={props.type}
            />
          </Box>
        );
      }}
    </Field>
  );
});

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.node,
  helperText: PropTypes.node,
  fullWidth: PropTypes.bool,
  remainWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  asSearchInput: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password']),
  value: PropTypes.string
};

TextField.defaultProps = {
  autoFocus: false,
  defaultValue: undefined,
  disabled: false,
  endIcon: undefined,
  error: false,
  errorText: undefined,
  fullWidth: false,
  remainWidth: false,
  helperText: undefined,
  label: undefined,
  multiline: false,
  asSearchInput: false,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  placeholder: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  rows: 2,
  rowsMax: 4,
  type: 'text',
  value: undefined
};

export default TextField;
