import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Field, useField } from 'react-final-form';
import { makeStyles } from '@mui/styles';

import Label from './Label';
import { compose, required } from '../utils/form/validators';

const useStyles = makeStyles(theme => {
  return {
    multiToggleFormControl: {
      '& fieldset': {
        border: 0,
        padding: 0
      }
    },
    multiToggleGroup: {
      ...(theme.components?.multiToggle?.group
        ? theme.components?.multiToggle?.group
        : {})
    },
    multiToggle: {
      color: theme.color.text.main,
      fontFamily: theme.font.primary,
      borderColor: theme.color.border.main,
      fontSize: '.875em',
      fontWeight: 500,
      padding: '.4em 1em',
      textTransform: 'none',
      '&:hover': {
        borderColor: theme.color.primary.dark,
        zIndex: 1
      },
      ...(theme.components?.multiToggle?.root
        ? theme.components?.multiToggle?.root
        : {})
    },
    activeToggle: {
      color: `${theme.color.primary.main} !important`,
      border: `1px solid ${theme.color.primary.main} !important`,
      zIndex: 1,
      ...(theme.components?.multiToggle?.activeToggle
        ? theme.components?.multiToggle?.activeToggle
        : {})
    },
    activeToggleDisabled: {
      color: `${theme.color.primary.light} !important`,
      border: `1px solid ${theme.color.primary.light} !important`
    }
  };
});

const MultiToggleInput = React.forwardRef(function MultiToggleInput(
  props,
  ref
) {
  const classes = useStyles(props);
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  function handleClick(option) {
    setSelected(option.index);

    props.update(option.index);
  }

  return (
    <ButtonGroup
      ref={ref}
      id={props.id}
      className={classes.multiToggleGroup}
      disabled={props.disabled}
      variant="outlined"
      aria-label="outlined button group"
    >
      {props.options.map(option => {
        return option.index === selected ? (
          <Button
            key={option.index}
            className={`${classes.multiToggle} ${
              props.disabled
                ? classes.activeToggleDisabled
                : classes.activeToggle
            }`}
            color="primary"
            disabled={props.disabled}
            data-testid={`multi_toggle_${props.id}`}
            data-selected={true}
            name={option.index?.toString()}
            onClick={() => handleClick(option)}
          >
            {option.title}
          </Button>
        ) : (
          <Button
            key={option.index}
            className={classes.multiToggle}
            disabled={props.disabled}
            data-testid={`multi_toggle_${props.id}`}
            data-selected={false}
            name={option.index?.toString()}
            onClick={() => handleClick(option)}
          >
            {option.title}
          </Button>
        );
      })}
    </ButtonGroup>
  );
});

export default function MultiToggle(props) {
  const field = useField(props.id);
  const [value, setValue] = useState(props.value);
  const classes = useStyles(props);

  // https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
  useImperativeHandle(props.inputRef, () => ({
    focus: () => {}
  }));

  function onChange(v, e) {
    field.input.onFocus();
    field.input.onChange(v, props.id);
    field.input.onBlur();

    setValue(v);

    if (props.onChange) props.onChange(v, props.id);
  }

  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);

    return validators;
  }

  function getDefaultValue(inputValue) {
    if (inputValue === '') {
      if (props.selected === undefined) {
        return props.defaultIndex;
      } else {
        return props.selected;
      }
    } else {
      return inputValue;
    }
  }

  return (
    <Field
      name={props.id}
      validate={compose(getValidators())}
      initialValue={props.initialValue}
    >
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');
        const internalValue = getDefaultValue(input.value);

        return (
          <FormControl
            error={error}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            className={classes.multiToggleFormControl}
          >
            <fieldset>
              <Label
                as="legend"
                disabled={props.disabled}
                required={props.required && !props.requiredWithoutAsterisk}
                popover={{
                  title: props.popoverTitle,
                  text: props.popoverText
                }}
              >
                {props.label}
              </Label>
              <Input
                id={props.id}
                type="radio"
                value={internalValue}
                disableUnderline
                inputProps={{
                  options: props.options,
                  disabled: props.disabled,
                  selected: internalValue,
                  update: onChange,
                  'aria-label': props.ariaLabel
                }}
                aria-describedby={`${props.id}-helper-text`}
                inputComponent={MultiToggleInput}
              />
              {(errorText || props.helperText) && (
                <FormHelperText
                  style={{ margin: 0 }}
                  id={`${props.id}-helper-text`}
                >
                  {errorText || props.helperText}
                </FormHelperText>
              )}
            </fieldset>
          </FormControl>
        );
      }}
    </Field>
  );
}

MultiToggle.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      index: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
    })
  ).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  defaultIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ariaLabel: PropTypes.string
};

MultiToggle.defaultProps = {
  disabled: false,
  label: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  error: false,
  errorText: undefined,
  selected: undefined,
  onChange: () => {},
  fullWidth: true,
  defaultIndex: undefined,
  initialValue: undefined,
  ariaLabel: ''
};
