import React, { useState, useRef, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  target: {
    cursor: props =>
      props.mode === 'click' && !props.disabled ? 'pointer' : 'auto',
    '&:focus': {
      outline: 'none'
    }
  },
  muiPopper: {
    zIndex: 95,
    pointerEvents: 'auto',
    // border: `1px solid ${theme.color.border.light}`,
    borderRadius: theme.borderRadius.small,
    fontFamily: theme.font.primary,
    boxShadow: theme.effect.boxShadow,
    background: theme.color.background.default,
    fontSize: '1em',
    width: props => props.width,
    maxWidth: props => props.maxWidth,
    '&:focus': {
      outline: 'none'
    }
  },
  closeButton: {
    background: '#0000002e',
    borderRadius: '1em',
    position: 'absolute',
    right: '1em',
    top: '1em',
    transform: 'scale(.75)',
    zIndex: 95,
    '& svg': {
      fill: theme.color.primary.contrastText,
      '&:hover': {
        fill: '#868181'
      }
    },
    ...(theme.components?.hoverbox?.closeButton
      ? theme.components?.hoverbox?.closeButton
      : {})
  }
}));

// https://zellwk.com/blog/keyboard-focusable-elements/
function getKeyboardFocusableElements(element = document) {
  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    )
  ].filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  );
}

export default function Hoverbox(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const [open, setOpen] = useState(props.open || false);
  const openRef = useRef(open);
  const [position, setPosition] = useState({
    x: undefined,
    y: undefined
  });
  const anchorRef = useRef(null);
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const hoverTimeoutRef = useRef();
  const functionMode = typeof props.target === 'function';

  function handlePopoverOpen(event) {
    if (!props.disabled) {
      setPosition({ x: event.pageX, y: event.pageY });
      setOpen(true);
      setTimeout(() => {
        contentRef?.current?.focus();
      });
      props.onOpen(event);
    }
  }

  function handlePopoverClose(event) {
    if (openRef.current) {
      if (
        props.disableCloseOnAchor &&
        anchorRef.current &&
        anchorRef.current.contains(event.target)
      ) {
        return;
      }
      setOpen(false);
      setTimeout(() => {
        anchorRef?.current?.focus();
      });
      props.onClose();
    }
  }

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  useEffect(() => setOpen(props.open), [props.open]);

  const handleCloseEsc = useCallback(event => {
    if (event.key === 'Escape' && openRef.current === true) {
      setOpen(false);

      setTimeout(() => {
        if (functionMode) {
          const focusableElements = getKeyboardFocusableElements(
            anchorRef?.current
          );
          if (focusableElements?.length) {
            focusableElements[0].focus();
          } else {
            anchorRef?.current?.focus();
          }
        } else {
          anchorRef?.current?.focus();
        }
      });
      props.onClose();
    }
  }, []);

  function handleFocus(e) {
    if (contentRef?.current && anchorRef?.current) {
      if (
        !contentRef?.current?.contains(e.target) &&
        !anchorRef?.current?.contains(e.target)
      ) {
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleCloseEsc, false);
    document.addEventListener('focusin', handleFocus, false);
    containerRef.current = document.getElementById(theme.contentId);

    return () => {
      document.removeEventListener('keydown', handleCloseEsc, false);
      document.removeEventListener('focusin', handleFocus, false);
    };
  }, []);

  function handleToggle(e) {
    if (!props.disabled) {
      if (open) {
        handlePopoverClose(e);
      } else {
        handlePopoverOpen(e);
      }
    }
  }

  return (
    <>
      {props.target && (
        <Box
          ref={anchorRef}
          className={classes.target}
          display="inline-flex"
          tabIndex={functionMode ? null : 0}
          // onKeyDown={
          //   !functionMode
          //     ? e => {
          //         if (e.key === 'Enter') {
          //           handleToggle(e);
          //         }
          //       }
          //     : null
          // }
          aria-describedby={open ? props.id : undefined}
          onClick={
            !functionMode
              ? e => {
                  if (props.mode === 'click') {
                    handleToggle(e);
                  }
                }
              : null
          }
          onMouseEnter={e => {
            if (props.mode === 'hover') {
              hoverTimeoutRef.current = setTimeout(() => {
                handlePopoverOpen(e);
              }, 250);
            }
          }}
          onMouseLeave={e => {
            if (props.mode === 'hover') {
              clearTimeout(hoverTimeoutRef.current);
              handlePopoverClose(e);
            }
          }}
          width={props.fullWidthTarget ? '100%' : null}
        >
          {functionMode ? props.target(open, handleToggle) : props.target}
        </Box>
      )}
      <Popper
        id={open ? props.id : undefined}
        open={open}
        disablePortal={props.disablePortal}
        keepMounted={props.keepMounted}
        ref={contentRef}
        tabIndex={-1}
        onMouseEnter={props.mode === 'hover' ? handlePopoverOpen : undefined}
        onMouseLeave={props.mode === 'hover' ? handlePopoverClose : undefined}
        anchorEl={
          props.position === 'mouse'
            ? {
                clientHeight: 0,
                clientWidth: 0,
                getBoundingClientRect: () => ({
                  top: position.y,
                  left: position.x,
                  right: position.x,
                  bottom: position.y,
                  width: 0,
                  height: 0
                })
              }
            : anchorRef?.current || props.customTarget
        }
        className={classes.muiPopper}
        placement={props.position !== 'mouse' ? props.position : 'bottom'}
        modifiers={[]
          .concat([
            {
              name: 'preventOverflow',
              options: {
                boundary: containerRef.current,
                padding: 8
              }
            }
          ])
          .concat(
            props.sticky
              ? [
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      altAxis: true,
                      altBoundary: true
                    }
                  }
                ]
              : []
          )}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={e => {
              if (
                !(anchorRef?.current || props.customTarget).contains(e.target)
              ) {
                handlePopoverClose(e);
              }
            }}
          >
            <Box p={props.borderless ? 0 : 2} className={classes.muiPopper}>
              {props.closeButton && (
                <div className={classes.closeButton}>
                  <IconButton
                    aria-label={
                      props.closeButtonAriaLabel
                        ? props.closeButtonAriaLabel
                        : 'close'
                    }
                    onClick={() => {
                      setOpen(false);
                      props.onClose();
                    }}
                    size="small"
                    title={
                      props.closeButtonTitle ? props.closeButtonTitle : 'close'
                    }
                  >
                    <Icon name="close" />
                  </IconButton>
                </div>
              )}
              {props.children}
            </Box>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

Hoverbox.propTypes = {
  mode: PropTypes.oneOf(['hover', 'click']),
  disabled: PropTypes.bool,
  borderless: PropTypes.bool,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  keepMounted: PropTypes.bool,
  sticky: PropTypes.bool,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  target: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  customTarget: PropTypes.any,
  position: PropTypes.oneOf([
    'left',
    'top',
    'right',
    'bottom',
    'bottom-end',
    'mouse',
    'right-end',
    'left-end',
    'right-start',
    'left-start'
  ]).isRequired,
  children: PropTypes.node,
  disablePortal: PropTypes.bool,
  disableCloseOnAchor: PropTypes.bool,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidthTarget: PropTypes.bool,
  closeButton: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTitle: PropTypes.string
};

Hoverbox.defaultProps = {
  disabled: false,
  mode: 'hover',
  borderless: false,
  sticky: false,
  customTarget: undefined,
  target: undefined,
  onChange: () => {},
  onClose: () => {},
  onOpen: () => {},
  children: undefined,
  portalRef: undefined,
  keepMounted: false,
  position: 'bottom',
  disablePortal: false,
  disableCloseOnAchor: false,
  open: false,
  fullWidthTarget: false,
  closeButton: false,
  closeButtonAriaLabel: 'close',
  closeButtonTitle: 'close'
};
