import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import ElementPicker from './ElementPicker';

const useStyles = makeStyles(theme => ({
  elementPickerElement: {
    padding: '0 0.75em',
    fontWeight: 500,
    margin: 0
  }
}));

export default function ElementPickerElement(props) {
  const classes = useStyles(props);
  const elementPickerContext = useContext(ElementPicker.Context);
  const isActive =
    elementPickerContext.mode === 'toggle'
      ? props.index === elementPickerContext.indices
      : elementPickerContext.indices?.find(index => index === props.index);

  function updateIndices() {
    if (isActive) {
      elementPickerContext.removeIndex(props.index);
    } else {
      elementPickerContext.addIndex(props.index);
    }
  }

  return (
    <Button
      className={classes.elementPickerElement}
      disabled={props.disabled || elementPickerContext.disabled}
      color={isActive ? 'primary' : 'default'}
      variant="outlined"
      data-selected={isActive}
      onClick={updateIndices}
    >
      {props.children}
    </Button>
  );
}

ElementPickerElement.propTypes = {
  children: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

ElementPickerElement.defaultProps = {
  onClick: () => {},
  disabled: false,
  active: false
};
