import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'groundkeeper-component-library';

export default function BodySidebar(props) {
  return (
    <Box flexShrink={0} flexBasis="33%" boxSizing="border-box">
      {props.children}
    </Box>
  );
}

BodySidebar.propTypes = {
  children: PropTypes.node
};

BodySidebar.defaultProps = {
  children: undefined
};
