import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Chip as MuiChip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  chip: {
    '& .MuiChip-root': {
      borderRadius: theme.borderRadius.small,
      border: ({ props, state }) => {
        switch (props.color) {
          default:
          case 'default':
            return state.selected
              ? `1px solid ${theme.color.background.grey}`
              : `1px solid ${theme.color.border.main}`;
          case 'primary':
            return state.selected
              ? `1px solid ${theme.color.primary.dark}`
              : `1px solid ${theme.color.primary.main}`;
          case 'secondary':
            return state.selected
              ? `1px solid ${theme.color.secondary.dark}`
              : `1px solid ${theme.color.secondary.main}`;
          case 'error':
            return state.selected
              ? `1px solid ${theme.color.error.main}`
              : `1px solid ${theme.color.error.main}`;
        }
      },
      backgroundColor: ({ state }) =>
        state.selected ? theme.color.background.grey : 'transparent',
      margin: 0,
      height: '1.8rem'
    },
    '& .MuiChip-label': {
      color: ({ props }) => {
        switch (props.color) {
          default:
          case 'default':
          case 'primary':
          case 'secondary':
            return theme.color.text.main;
          case 'error':
            return theme.color.text.main;
        }
      },
      fontSize: theme.font.size.small
    }
  },
  closeButton: {
    color: theme.color.common.grey.main
  }
}));

export default function Chip(props) {
  const [selected, setSelected] = useState(props.selected);
  const classes = useStyles({ props, state: { selected } });

  function handleClick() {
    if (props.onClick) {
      setSelected(!selected);
      props.onClick();
    }
  }

  function handleDelete() {
    props.onClose();
  }

  useEffect(() => {
    if (props.selected !== selected) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  return (
    <>
      <Box className={classes.chip} title={props.title}>
        <MuiChip
          clickable={!!props.onClick}
          label={props.children}
          onClick={handleClick}
          onDelete={typeof props.onClose === 'function' ? handleDelete : null}
          deleteIcon={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.closeButton}
            >
              <Icon name="close" />
            </Box>
          }
        ></MuiChip>
      </Box>
    </>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error']),
  onClose: PropTypes.func,
  selected: PropTypes.bool
};

Chip.defaultProps = {
  onClick: undefined,
  onClose: undefined,
  selected: false
};
