import { Contact } from 'shared/Redux/interfaces';

export default function getDefaultContact(contacts: Contact[]): Contact {
  if (!contacts?.length) return undefined;

  // Since there is no currently no type at contact field
  // we use the first entry as default
  const defaultContact = contacts[0];
  return {
    ...defaultContact,
    display_name: `${defaultContact?.first_name} ${defaultContact?.last_name}`
  };
}
