import Cookies from 'js-cookie';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import axios from 'axios';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import { isDate } from 'groundkeeper-component-library';

import {
  configureStore,
  getDefaultMiddleware,
  isPlain
} from '@reduxjs/toolkit';

import isClient from './utils/isClient';
import isServer from './utils/isServer';
import { getServerSessionToken } from './api/session';
import { reducers } from './slices';

const makeStore = context => {
  if (isServer) {
    // Set authentication header for axios here because context is
    // only available here for SSR.
    // Maybe get a better solution and make the request.cookies
    // avaialbe in api-client
    const sessionToken = getServerSessionToken(context);

    if (sessionToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${sessionToken}`;
    } else {
      axios.defaults.headers.common.Authorization = null;
    }

    // Set server-side timezone
    axios.defaults.headers.common.timezone = context?.ctx?.req?.cookies
      ? context?.ctx?.req?.cookies.timezone
      : 'UTC';

    // Set server-side timezone offset
    axios.defaults.headers.common.timezoneOffset = context?.ctx?.req?.cookies
      ? context?.ctx?.req?.cookies['timezone-offset']
      : '0';

    const store = configureStore({
      reducer: reducers,
      middleware: getDefaultMiddleware({
        serializableCheck: false
      }),
      devTools: true
    });

    return store;
  }

  if (isClient) {
    // Set client-side timezone
    axios.defaults.headers.common.timezone = Cookies.get('timezone') || 'UTC';

    // Set client-side timezone offset
    axios.defaults.headers.common.timezoneOffset =
      Cookies.get('timezone-offset') || '0';
  }

  // we need it only on client side
  const {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
    // eslint-disable-next-line global-require
  } = require('redux-persist');
  // eslint-disable-next-line global-require
  const storage = require('redux-persist/lib/storage').default;

  // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  const persistConfig = {
    key: 'application',
    whitelist: ['application', 'auth', 'seasons'],
    storage,
    stateReconciler: autoMergeLevel2
  };

  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (value: any) => isPlain(value) || isDate(value),
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          HYDRATE
        ]
      }
    }),
    devTools: true
  });

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  store.__persistor = persistStore(store);

  return store;
};

// eslint-disable-next-line import/prefer-default-export
export const wrapper = createWrapper<ReturnType<typeof makeStore>>(makeStore);
