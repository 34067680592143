import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';
import Text from './Text';
import InformationGrid from './InformationGrid';

export function isEmptyItem(props) {
  return (
    props?.children === null ||
    props?.children === undefined ||
    props?.children === ''
  );
}

const useStyles = makeStyles(theme => ({
  gridRow: {
    verticalAlign: 'top',
    '& td,th': {
      verticalAlign: ({ props }) => (props.icon ? 'middle' : 'top'),
      padding: `0 0 ${theme.spacing(1.25)} 0`
    },
    '& th': {
      width: ({ props, context }) =>
        props.labelWidth ? props.labelWidth : context.labelWidth,
      boxSizing: 'border-box',
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      paddingRight: theme.spacing(2)
    },
    '& td': {
      paddingLeft: theme.spacing(1)
    },
    '&:last-of-type': {
      paddingBottom: ({ context }) => (context.context ? 0 : theme.spacing(1))
    },
    ...(theme.components?.informationGrid?.item
      ? theme.components?.informationGrid?.item
      : {})
  }
}));

export default function InformationGridItem(props) {
  const informationGridContext = useContext(InformationGrid.Context);
  const classes = useStyles({ context: informationGridContext, props });

  return !isEmptyItem(props) ? (
    <tr className={classes.gridRow}>
      <th>
        {props.icon ? <Icon name={props.icon} color={props.iconColor} /> : null}
        {props.value ? (
          <Text size={informationGridContext.size} bold>
            {props.value}
          </Text>
        ) : null}
      </th>
      <td>
        <Text size={informationGridContext.size}>{props.children}</Text>
      </td>
    </tr>
  ) : null;
}

InformationGridItem.propTypes = {
  value: PropTypes.node,
  labelWidth: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node
};

InformationGridItem.defaultProps = {
  value: undefined,
  labelWidth: undefined,
  icon: undefined,
  children: undefined
};
