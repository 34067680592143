import PropTypes from 'prop-types';
import React from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  muiTablePagination: {
    '& .MuiTablePagination-toolbar': {
      minHeight: 0
    },
    '& .MuiTablePagination-actions button': {
      padding: '.25em'
    }
  }
}));

export default function TablePagination(props) {
  const classes = useStyles(props);

  function handlePageChange(e, p) {
    props.handleChangePage(e, p);

    // Scroll table into view
    if (props.tableRef && props.tableRef.current) {
      const rect = props.tableRef.current.getBoundingClientRect();

      // Only scroll table into view if top is not visible already
      if (rect.top < 0) {
        props.tableRef.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }
    }
  }

  const maybeDisabled =
    typeof props.disabled == 'boolean' && props.disabled === true
      ? { disabled: props.disabled }
      : {};

  return (
    <MuiTablePagination
      component="div"
      data-testid="pagination_controls"
      className={classes.muiTablePagination}
      count={props.count}
      nextIconButtonProps={{
        ...maybeDisabled
      }}
      backIconButtonProps={{
        ...maybeDisabled
      }}
      SelectProps={{
        labelId: 'pagination-select-label',
        inputProps: {
          'aria-labelledby': 'pagination-select-label'
        },
        ...maybeDisabled
      }}
      rowsPerPageOptions={props.rowsPerPageOptions}
      onPageChange={(e, p) => handlePageChange(e, p)}
      onRowsPerPageChange={props.handleChangeRowsPerPage}
      // TBD: Integrate internationalization
      labelRowsPerPage="Anzahl pro Seite"
      getItemAriaLabel={type => {
        let typeTranslation;
        switch (type) {
          case 'first':
            typeTranslation = 'ersten';
          case 'last':
            typeTranslation = 'letzten';
          case 'next':
            typeTranslation = 'nächsten';
          case 'previous':
            typeTranslation = 'vorherigen';
        }

        return `Gehe zur ${typeTranslation} Seite`;
      }}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
    />
  );
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

TablePagination.defaultProps = {
  handleChangePage: () => {},
  tableRef: undefined,
  count: 0,
  page: 0,
  rowsPerPage: 0,
  rowsPerPageOptions: [10, 15, 25, 50, 100]
};
