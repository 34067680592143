import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import classNames from '../utils/react/classNames';
import Hoverbox from './Hoverbox';
import Icon from './Icon';
import Heading from './Heading';
import Text from './Text';
import { IconButton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    color: theme.color.text.main,
    columnGap: theme.spacing(0.5),
    display: 'flex',
    fontFamily: theme.font.primary,
    fontSize: theme.font?.size?.input,
    fontWeight: 'normal',
    lineHeight: 'normal',
    marginBottom: props => (props.hidden ? 0 : theme.spacing(0.75)),
    ...(theme.components?.label?.root ? theme.components?.label?.root : {})
  },
  disabled: {
    color: theme.color.text.light
  },
  infoboxIcon: {
    padding: 0
  }
}));

export default function Label(props) {
  const classes = useStyles(props);

  return props.children ? (
    <Box
      className={classNames([
        classes.label,
        props.disabled ? classes.disabled : null,
        props.className
      ])}
    >
      {props.popover?.title && props.popover?.text ? (
        <Hoverbox
          position="bottom"
          id={`infobox-${props.for ?? props.children}`}
          mode="click"
          maxWidth="20rem"
          target={(open, handleClick) => (
            <IconButton
              size="small"
              onClick={e => handleClick(e)}
              className={classes.infoboxIcon}
              // TBD: Intl
              aria-label="Sie erhalten eine Erklärung zu dem nachfolgendem Feld"
            >
              <Icon
                name={
                  props.popover?.customIcon
                    ? props.popover?.customIcon
                    : 'info-circle'
                }
              />
            </IconButton>
          )}
        >
          <Heading type="h3">{props.popover?.title}</Heading>
          <Text>{props.popover?.text}</Text>
        </Hoverbox>
      ) : null}
      <props.as
        hidden={props.hidden}
        htmlFor={props.for}
        id={`${props.for ?? props.children}-label`}
      >
        {props.children}
        {props.required && (
          // TBD: Intl
          <span aria-label="Das nachfolgende Feld ist für das Absenden des Formulars erforderlich">
            {'*'}
          </span>
        )}
      </props.as>
    </Box>
  ) : null;
}

Label.propTypes = {
  as: PropTypes.oneOf(['label', 'legend']),
  for: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.string,
  className: PropTypes.string,
  popover: PropTypes.shape({
    customIcon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
  })
};

Label.defaultProps = {
  as: 'label',
  disabled: false,
  popover: undefined,
  required: false,
  hidden: undefined,
  className: undefined,
  popover: undefined
};
